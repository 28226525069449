import { Radio, Card } from '@mui/material';
import { Done } from '@mui/icons-material';
import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';
import { primaryColor } from '../../../themes/_colors';
import { square } from '../../../themes/_mixins';

export const CustomRadio = styled(Radio)`
  ${square(3)}
`;

export const RadioChecked = styled.div`
  background-color: ${primaryColor};
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
  border-radius: 50%;
  &:before {
    ${square(2.5)};
    display: block;
    background-image: radial-gradient(#fff, #fff 28%, transparent 32%);
    content: '';
  }
`;

export const Section = styled.section`
  padding: 18px 0;
`;

export const CheckIcon = styled(Done)`
  color: ${({ theme }: ITheme): string => theme.palette.success.light};
  margin-right: 8px;
  margin-top: -2px;
`;

interface ICustomCardProps extends ITheme {
  $checked: boolean;
}

export const CustomCard = styled(Card)`
  padding: 0px;
  border-radius: 6px;
  ${({ $checked, theme }: ICustomCardProps): CssStyles =>
    css`
      border: 2px solid ${$checked ? theme.palette.primary.main : 'transparent'};
    `}
  max-width: 328px;
`;
