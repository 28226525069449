import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

const StyledB1 = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const StyledB2 = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const StyledB3 = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const StyledB4 = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

type Variants = 'b1' | 'b2' | 'b3' | 'b4';
interface IVariant {
  variant?: Variants;
}

const Buttons = {
  b1: StyledB1,
  b2: StyledB2,
  b3: StyledB3,
  b4: StyledB4,
};

const Button: React.FC<Omit<TypographyProps, 'variant'> & IVariant> = ({
  children,
  variant = 'b1',
  ...props
}) => {
  const SelectedButton = Buttons[variant];

  return <SelectedButton {...props}>{children}</SelectedButton>;
};

export default Button;
