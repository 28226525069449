export enum QueryKeys {
  pswdToken = 'pswdToken',
  SendToken = 'sendToken',
  addCard = 'addCard',
  banners = 'banners',
  bikeStations = 'bikeStations',
  getCard = 'getCard',
  plans = 'plans',
  planDetail = 'planDetail',
  qrCode = 'qrCode',
  pendingRewards = 'pendingRewards',
  expiredRewards = 'expiredRewards',
  withdrawnRewards = 'withdrawnRewards',
  userData = 'userData',
  userPlan = 'userPlan',
  futurePlan = 'futurePlan',
  actionButtons = 'actionButtons',
  useUser = 'useUser',
  useUserState = 'useUserState',
  preSignup = 'preSignup',
  signUpDocument = 'signUpDocument',
  bookingAvailability = 'bookingAvailability',
  getPlans = 'getPlans',
  getUserBooking = 'getUserBooking',
  getUserCard = 'getUserCard',
  getQrCode = 'getQrCode',
  getReturnDetail = 'getReturnDetail',
  getAvailability = 'getAvailability',
  useScheduleDetailQuery = 'useScheduleDetailQuery',
}
