import { Color, TypeBackground } from '@mui/material';
import { ITheme } from '../interfaces/theme.interface';

export const disabledColor = ({ theme }: ITheme): string => theme.palette.grey[100];
export const greyColor =
  (scale: keyof Color) =>
  ({ theme }: ITheme): string =>
    theme.palette.grey[scale];
export const primaryColor = ({ theme }: ITheme): string => theme.palette.primary.main;
export const whiteColor = ({ theme }: ITheme): string => theme.palette.common.white;
export const blackColor = ({ theme }: ITheme): string => theme.palette.common.black;
export const errorColor = ({ theme }: ITheme): string => theme.palette.error.main;
export const fontColor = ({ theme }: ITheme): string => theme.palette.text.primary;
export const backgroundColor =
  (variant: keyof TypeBackground) =>
  ({ theme }: ITheme): string =>
    theme.palette.background[variant];
