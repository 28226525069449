import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

const StyledD1 = styled(Typography)`
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
`;

const StyledD2 = styled(Typography)`
  font-size: 40px;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: 0em;
`;

const StyledD3 = styled(Typography)`
  font-size: 30px;
  font-style: italic;
  font-weight: 1;
  line-height: 30px;
  letter-spacing: 0.25px;
`;

const StyledD4 = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

const StyledD5 = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 10px;
`;

type Variants = 'd1' | 'd2' | 'd3' | 'd4' | 'd5';
interface IVariant {
  variant?: Variants;
}

const displays = {
  d1: StyledD1,
  d2: StyledD2,
  d3: StyledD3,
  d4: StyledD4,
  d5: StyledD5,
};

const Display: React.FC<Omit<TypographyProps, 'variant'> & IVariant> = ({
  children,
  variant = 'd1',
  ...props
}) => {
  const SelectedDisplay = displays[variant];

  return <SelectedDisplay {...props}>{children}</SelectedDisplay>;
};

export default Display;
