import { useParams } from 'react-router-dom';
import { PLANS } from '../API/tempartner';
import { QueryKeys } from '../constants/queryKeys.const';
import { IPlan, IPLanResponse } from '../interfaces/plan.interface';
import { UseBuildCustomQuery } from '../interfaces/query.interface';
import { normalizePlan } from '../utils/plan';
import { buildPostRequest } from '../utils/request';
import { useGuardedQuery } from './useGuardedQuery';

interface IParams {
  planId: string;
}

export const usePlanDetail: UseBuildCustomQuery<IPlan> = (options = {}) => {
  const { planId } = useParams<keyof IParams>();
  const data = { plan_id: planId };
  const url: string = PLANS.DETAIL;

  const request = buildPostRequest<IPLanResponse, IPlan>({
    url,
    data,
    select: result => normalizePlan(result),
  });

  return useGuardedQuery<IPlan>([QueryKeys.planDetail], () => request(), options);
};
