/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { User } from '../../../temCore';
import { AnalyticsInternalServiceInterface, BrazeApp, EmitEventInternalType } from '../types';

export class BrazeAnalyticsService implements AnalyticsInternalServiceInterface {
  /* ---------------------------- Singleton Instance --------------------------- */
  static #instance: BrazeAnalyticsService;

  static get instance(): BrazeAnalyticsService {
    return BrazeAnalyticsService.#instance;
  }

  /* ------------------------------- Properties ------------------------------- */
  #braze: BrazeApp;

  constructor(brazeApp: BrazeApp) {
    this.#braze = brazeApp;
  }

  static readonly setup = (brazeApp: BrazeApp): void => {
    BrazeAnalyticsService.#instance = new BrazeAnalyticsService(brazeApp);
  };

  /* ------------------------------- Methods ------------------------------- */
  /**
   * Emits an event to Braze
   * @param name name of event to emit
   * @param params params to send for destination
   */
  emitEvent: EmitEventInternalType = (name, params) => {
    this.#braze.logCustomEvent(name, params);
  };

  /**
   *  Sets the user and opens Braze session
   * @param user
   */
  setUser = (user: User): void => {
    if (user.email) {
      this.#braze.changeUser(user.email);
      // we must call openSession again after change the user.
      this.#braze.openSession();
    }
  };

  /**
   * Sets a custom user property
   */
  setUserProperty = (key: string, value: string): void => {
    if (!!key && value) {
      this.#braze.getUser()?.addToCustomAttributeArray(key, value);
    }
  };
}
