import { Button } from '../../atoms';
import * as S from './style';

interface IButtonProps {
  onClick: () => void;
  content: string;
  isSubmitting?: boolean;
  testId?: string;
}

interface IButtonGroupProps {
  main: IButtonProps;
  secondary: IButtonProps;
  bottom?: boolean;
}

const ButtonGroup: React.FC<IButtonGroupProps> = ({ main, secondary, bottom = false }) => {
  return (
    <S.MainAction $bottom={bottom}>
      <Button
        data-testid={main.testId}
        color="primary"
        type="button"
        onClick={main.onClick}
        isSubmitting={main.isSubmitting}
      >
        {main.content}
      </Button>

      <Button
        color="primary"
        type="button"
        variant="text"
        data-testid={secondary.testId}
        onClick={secondary.onClick}
        isSubmitting={secondary.isSubmitting}
      >
        {secondary.content}
      </Button>
    </S.MainAction>
  );
};

export default ButtonGroup;
