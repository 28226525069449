import styled from 'styled-components';
import { InputAdornment, TextField } from '@mui/material';
import { greyColor, fontColor, whiteColor, primaryColor } from '../../../themes/_colors';

export const CreditCardField = styled(TextField)`
  border: 1px solid ${greyColor(50)};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: 2px solid ${greyColor(50)};
  }

  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root,
  .MuiInputBase-root.Mui-disabled {
    background-color: ${whiteColor};
    color: ${fontColor};
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }
`;

export const Adornement = styled(InputAdornment)`
  .MuiTypography-body1 {
    color: ${primaryColor};
    font-weight: 600;
  }
`;
