import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';
import { DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { ITheme } from '../../../interfaces/theme.interface';
import { spacing } from '../../../themes/_helpers';

export const Content = styled(DialogContent)`
  padding: 24px;
  padding-bottom: 0px;
  text-align: center;
  margin: 12px 6px 36px 6px;
  color: ${({ theme }: ITheme): string => theme.palette.text.secondary};
`;

export const Image = styled.div`
  width: 180px;
  margin-bottom: 20px;
`;

export const Title = styled(DialogTitle)`
  font-weight: bold;
  margin-bottom: 10px;
  & * {
    font-size: 20px;
  }
`;

export const Message = styled(DialogContentText)`
  font-size: 14px;
`;

interface IActionsProps extends ITheme {
  direction: 'row' | 'column';
}

export const Actions = styled(DialogActions)`
  padding: 24px;
  padding-top: 0px;
  display: flex;
  gap: ${spacing(1)};
  & > :not(:first-child) {
    margin: 0;
  }
  flex-direction: ${({ direction }: IActionsProps): string => direction};
  ${({ direction }: IActionsProps): false | CssStyles =>
    direction === 'row' &&
    css`
      & * {
        flex-basis: 50%;
      }
    `};
`;

interface ISplashImageProps {
  minHeight?: string;
  width?: string;
}

export const SplashImage = styled.img`
  min-height: ${({ minHeight }: ISplashImageProps): string => minHeight ?? '20px'};
  margin: 0 auto;
  display: block;
  ${({ width }): CssStyles | false =>
    !!width &&
    css`
      width: ${width};
    `}
`;

export const Atendimento = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-top: 24px;
`;
