export const STORAGE = {
  LOCATION: 'location',
  USER_DATA: 'user-data',
  SIGNED: 'signed',
  SELECTED_PLAN_ID: 'selected-plan-id',
  CURRENT_PLAN_PAYMENT_TYPE: 'current_plan_payment_type',
  PUBLIC_ID: 'public_id',
  SYSTEM_ID: 'system_id',
  SELECT_EXTERNAL: 'select-external',
  APP_VERSION: 'app-version',
  APP_VARIANT: 'app-variant',
  OPEN_TRIP_INFO: 'open_trip_info',
  REWARD_SELECTED_PANEL: 'reward_selected_panel',
  REWARD_SELECTED_LIST: 'reward_selected_list',
  UNLOCK_BIKE_V2_FIRST_ACCESS: 'unlock_bike_v2_first_access',
  LOCK_BIKE_V2_FIRST_ACCESS: 'lock_bike_v2_first_access',
} as const;
