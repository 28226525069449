import { EVENTS } from '../../../constants/events.const';
import Events from '../../../services/events.service';

export default {
  onClick: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.CODE_GENERATE_CLICK,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    }),
};
