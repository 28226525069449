import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

const StyledS1 = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const StyledS2 = styled(Typography)`
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.4px;
`;

type Variants = 's1' | 's2';
interface IVariant {
  variant?: Variants;
}

const SubTitles = {
  s1: StyledS1,
  s2: StyledS2,
};

const SubTitle: React.FC<Omit<TypographyProps, 'variant'> & IVariant> = ({
  children,
  variant = 's1',
  ...props
}) => {
  const SelectedSubTitle = SubTitles[variant];

  return (
    <SelectedSubTitle variant="subtitle1" {...props}>
      {children}
    </SelectedSubTitle>
  );
};

export default SubTitle;
