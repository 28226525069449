import { Box, ListItemSecondaryAction } from '@mui/material';
import { EVENTS } from '../../../constants/events.const';
import { IStation } from '../../../interfaces/stations.interface';
import Events from '../../../services/events.service';
import { openMaps } from '../../../utils/format';
import { IconMap } from '../../atoms/Icons';
import * as S from './style';

interface IStationItemProps {
  station: IStation;
  showBikes: boolean;
}

export const BIKES_MAX_DISPLAY = 50;

const StationItem: React.FC<IStationItemProps> = ({ station, showBikes }) => {
  const onClick = (): void => {
    Events.logEvent({
      action: EVENTS.ACTIONS.ELETRIC_BIKE_MAPS_CLICK,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    });

    window.location.replace(openMaps(station.coords));
  };

  const formattedBikes =
    station.bikes > BIKES_MAX_DISPLAY ? `+${BIKES_MAX_DISPLAY}` : station.bikes;

  return (
    <S.CustomListItem
      $disabled={!station.bikes && showBikes}
      onClick={onClick}
      data-testid="customItemLink"
    >
      <Box display="flex" align-items="start" width="80%">
        <IconMap />
        <S.ItemText primary={station.name} secondary={station.address} />
      </Box>
      {showBikes && (
        <ListItemSecondaryAction data-testid="list-secondary-item">
          <S.Circle $disabled={!station.bikes}>{formattedBikes}</S.Circle>
        </ListItemSecondaryAction>
      )}
    </S.CustomListItem>
  );
};

export default StationItem;
