import { NavigateNext } from '@mui/icons-material';
import { ListItemSecondaryAction, ListItemText } from '@mui/material';
import * as S from './style';

interface IActionItemProps {
  onClick: () => void;
  content: string;
}

const ActionItem: React.FC<IActionItemProps> = ({ onClick, content }) => (
  <S.CustomItem onClick={onClick}>
    <ListItemText primary={content} />
    <ListItemSecondaryAction>
      <S.CustomIcon edge="end" aria-label="arrow-right">
        <NavigateNext />
      </S.CustomIcon>
    </ListItemSecondaryAction>
  </S.CustomItem>
);

export default ActionItem;
