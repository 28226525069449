import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Body, Button, Main } from '../../components/atoms';
import { NavBar } from '../../components/organisms';
import { ROUTES } from '../../constants/routes.const';
import { CONTENT } from './content';
import * as S from './styles';
import warningListInfoEvents from './warningListInfo.events';
import { useCancelAutoMigration } from '../../hooks/useCancelAutoMigration';
import { messageRoute } from '../../utils/route';
import { MessageTypes } from '../InfoView/messages.const';
import { ILoggedUser, IUser, PenaltyStatus } from '../../interfaces/userData.interface';
import { QueryKeys } from '../../constants/queryKeys.const';

const WarningListInfo = (): JSX.Element => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<IUser>(QueryKeys.userData) as ILoggedUser;
  const isCancelCreditCardDisabled =
    !!user?.penalty_status && user.penalty_status === PenaltyStatus.warning_inactive_auto_migration;

  useEffect(() => warningListInfoEvents.pageView(), []);
  const navigate = useNavigate();
  const { mutate } = useCancelAutoMigration({
    onError: () => {
      navigate(messageRoute(MessageTypes.ERROR_SERVER));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.userData);
      navigate(messageRoute(MessageTypes.CANCEL_AUTO_MIGRATION_SUCCESS));
    },
  });

  const handleClickOk = () => {
    warningListInfoEvents.clickOk();
    navigate(ROUTES.HUB);
  };
  const handleCancelCreditCard = () => {
    warningListInfoEvents.clickCancel();
    mutate();
  };
  return (
    <Body>
      <NavBar backRoute={ROUTES.WARNING_LIST} title={CONTENT.knowMore} />
      <Main>
        <Box display="flex" flexDirection="column" mt={3} gap={4} paddingX={2}>
          <Typography align="center" color="textSecondary" variant="h3" fontWeight="bold">
            {CONTENT.title}
          </Typography>

          {CONTENT.block.map(item => (
            <Box key={item.id} display="flex" flexDirection="column" paddingX={2}>
              <S.SplashImage src={item.image} />
              <Typography align="center" color="textSecondary" variant="p2">
                {item.message}
              </Typography>
            </Box>
          ))}

          <Box display="flex" flexDirection="column" gap={1}>
            <Button
              data-testid="primary-button"
              fullWidth
              color="primary"
              type="button"
              onClick={handleClickOk}
            >
              {CONTENT.primaryButtonText}
            </Button>

            <Button
              data-testid="secondary-button"
              color="primary"
              type="button"
              variant="outlined"
              onClick={handleCancelCreditCard}
              disabled={isCancelCreditCardDisabled}
            >
              {CONTENT.secondaryButtonText}
            </Button>
          </Box>
        </Box>
      </Main>
    </Body>
  );
};

export default WarningListInfo;
