import { Dialog, DialogContent, DialogContentText, Typography } from '@mui/material';
import styled from 'styled-components';
import { backgroundColor, greyColor, whiteColor } from '../../../themes/_colors';
import { spacing } from '../../../themes/_helpers';

export const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    height: calc(100vh - ${spacing(3)});
    border-top-left-radius: ${spacing(1)};
    border-top-right-radius: ${spacing(1)};
  }

  .MuiDialog-container {
    align-items: end;
  }
`;

export const Content = styled(DialogContent)`
  background: ${greyColor(100)};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spacing(1.5)};
  padding-bottom: 0;
`;

export const ActionsContainer = styled.section`
  margin-top: auto;
  gap: ${spacing(1)};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ClockContainer = styled.section`
  gap: ${spacing(1)};
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(2)};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const QrCodeContainer = styled.section`
  background-color: ${whiteColor};
  margin-top: ${spacing(3)};
  width: ${spacing(32)};
  padding: ${spacing(2.25)};
  border-radius: ${spacing(1.25)};
  border: 1px solid ${greyColor(300)};
`;

export const Line = styled.div`
  width: ${spacing(5)};
  height: ${spacing(0.75)};
  border-radius: ${spacing(1)};
  background-color: ${greyColor(300)};
  margin: 0 auto ${spacing(3)} auto;
  flex-shrink: 0;
`;

export const Title = styled(DialogContentText)`
  font-size: ${spacing(1.75)};
`;

export const Highlighted = styled(Typography)`
  position: relative;
  background-color: ${backgroundColor('ice')};
  border-radius: ${spacing(0.5)};
  font-size: ${spacing(1.5)};
  padding: ${spacing(1)} ${spacing(2)};
  margin-top: ${spacing(2.5)};
`;
