import { List } from '@mui/material';
import { CheckItem } from '../../molecules';

interface IListBenefitsProps {
  benefits: string[];
}

const ListBenefits: React.FC<IListBenefitsProps> = ({ benefits }) => {
  return (
    <List disablePadding>
      {benefits.map((benefit, idx) => (
        <CheckItem benefit={benefit} key={idx} />
      ))}
    </List>
  );
};

export default ListBenefits;
