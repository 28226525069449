export const CONTENT = {
  header: 'Seu plano pode mudar',
  title:
    'Você está pouco tempo disponível com a bike para fazer entregas iFood e seu plano pode mudar',
  subtitle: 'O que muda:',
  accessRestriction: 'Restrição de acesso e benefícios nos pontos de apoio',
  externalTembici: 'Desbloqueio apenas da bikes pelo app Tembici',
  externalBikeItau: 'Desbloqueio apenas das bikes laranjinha do bike Itaú',
  knowMore: 'Saiba mais',
  planChange: 'Ajuste no valor do plano',
};
