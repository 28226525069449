import { IErrorNormalized } from '../../../interfaces/error.interface';
import { MessageTypes } from '../../../pages/InfoView/messages.const';
import { getCustomError, isCustomError, isGivenCustomError } from '../../../services/error.service';
import { ERROR_DETAILS } from '../../../utils/serverErrorHandler';

const AlerErrorData = {
  USER_HAS_OPEN_TRIP: {
    messageType: MessageTypes.ERROR_HAS_QRCODE,
    supportChat: true,
  },
  OTHER: {
    messageType: MessageTypes.ERROR_SERVER,
  },
  DEFAULT: {
    messageType: MessageTypes.ERROR_GENERATE_QRCODE,
  },
};

interface IAlertProps {
  messageType: MessageTypes;
  message?: string | null;
  supportChat?: boolean;
}
export const alertDataContent = (error: IErrorNormalized | null): IAlertProps | null => {
  if (!error) return null;

  if (isGivenCustomError(error, ERROR_DETAILS.USER_HAS_OPEN_TRIP)) {
    return AlerErrorData.USER_HAS_OPEN_TRIP;
  }

  if (isCustomError(error)) {
    const message = getCustomError(error);
    return { ...AlerErrorData.OTHER, message };
  }

  return AlerErrorData.DEFAULT;
};
