import styled from 'styled-components';
import { Checkbox as CheckMaterial } from '@mui/material';
import { disabledColor } from '../../../themes/_colors';

const Checkbox = styled(CheckMaterial)`
  &:not(.Mui-checked) .MuiIconButton-label {
    color: ${disabledColor};
    background-color: ${disabledColor};
    border-color: ${disabledColor};
  }
`;

export default Checkbox;
