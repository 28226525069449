import { Box, Dialog } from '@mui/material';
import { HEADER_TITLE } from '../../../constants/header.const';
import { Button } from '../../atoms';
import NavBar from '../NavBar';

interface ITermsProps {
  open: boolean;
  handleClose: () => void;
  buttonText: string;
  src: string;
  handleAccept: () => void;
}

const Terms: React.FC<ITermsProps> = ({ handleClose, open, buttonText, src, handleAccept }) => (
  <Dialog fullScreen open={open} data-testid="dialog-terms">
    <NavBar title={HEADER_TITLE.TERMS} closePage={handleClose} />
    <Box flexGrow={1}>
      <iframe
        src={src}
        title="Termos e Condições"
        width="100%"
        height="100%"
        data-testid="iframe-terms"
      />
    </Box>
    <Box padding={2}>
      <Button onClick={handleAccept} data-testid="accept-terms" color="primary">
        {buttonText}
      </Button>
    </Box>
  </Dialog>
);

export default Terms;
