import { createContext, useState } from 'react';
import { getItem, storeItem } from '../utils/localStorage';
import { STORAGE } from '../constants/storage.const';
import { useQueryParams } from '../hooks/useQueryParams';
import { Region } from '../constants/regions.const';
import { PARAMS } from '../constants/params.const';

export interface ReturnDetails {
  date: string | number | Date;
  pickup_point: string;
  pickup_point_address: string;
  period: 'morning' | 'afternoon';
}

type Timer = 'backward' | 'forward';

export interface IUserTripInfo {
  remaining_time: string;
  isDelayed: boolean;
  bike_mnsbc?: string;
  modal?: string;
  start_date: string;
  end_date: string;
  timer_type: Timer;
  return_details?: ReturnDetails;
  is_support_buffer_bike: boolean;
  has_open_trip: boolean;
}

export interface DriverInfo {
  publicId: string;
  region: string;
  email: string;
  emailBpv: string;
  userState: string;
  refreshToken: string;
  userTripInfo?: IUserTripInfo;
  tokenDocument: string;
  userHasCreditCard: boolean;
  fitForBpv: boolean;
}

export interface IDriverContext extends DriverInfo {
  setEmail: (email: string) => void;
  setEmailBpv: (email: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  setUserState: (userState: string) => void;
  setTokenDocument: (tokenDocument: string) => void;
  setUserTripInfo: (tripInfo?: IUserTripInfo) => void;
  setUserHasCreditCard: (hasCreditCard: boolean) => void;
  setFitForBpv: (fitForBpv: boolean) => void;
}

export const DriverContext = createContext<IDriverContext>({
  publicId: '',
  region: '',
  email: '',
  emailBpv: '',
  refreshToken: '',
  userState: '',
  tokenDocument: '',
  userTripInfo: undefined,
  userHasCreditCard: false,
  fitForBpv: false,
  setTokenDocument: () => null,
  setEmail: () => null,
  setEmailBpv: () => null,
  setRefreshToken: () => null,
  setUserState: () => null,
  setUserTripInfo: () => null,
  setUserHasCreditCard: () => null,
  setFitForBpv: () => null,
});

export const useDriver = (): IDriverContext => {
  const [email, setEmail] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [userState, setUserState] = useState('');
  const [tokenDocument, setTokenDocument] = useState('');
  const [userTripInfo, setUserTripInfo] = useState<IUserTripInfo>();
  const [userHasCreditCard, setUserHasCreditCard] = useState<boolean>(false);
  const [emailBpv, setEmailBpv] = useState('');
  const [fitForBpv, setFitForBpv] = useState(false);

  const params = useQueryParams();
  const publicId = params.get(PARAMS.PUBLIC_ID) || getItem(STORAGE.PUBLIC_ID) || '';
  const region = params.get(PARAMS.REGION) || getItem<Region>(STORAGE.LOCATION) || '';

  storeItem<string>(STORAGE.PUBLIC_ID, publicId);
  storeItem<string>(STORAGE.LOCATION, region);

  return {
    publicId,
    region,
    email,
    refreshToken,
    userState,
    tokenDocument,
    userTripInfo,
    userHasCreditCard,
    emailBpv,
    fitForBpv,
    setFitForBpv,
    setEmailBpv,
    setUserTripInfo,
    setTokenDocument,
    setUserState,
    setEmail,
    setRefreshToken,
    setUserHasCreditCard,
  };
};
