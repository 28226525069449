import { CheckCircleRounded } from '@mui/icons-material';
import styled from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';
import { spacing } from '../../../themes/_helpers';

interface IStyledBatchProps extends ITheme {
  $done: boolean;
}

export const CheckIcon = styled(CheckCircleRounded)`
  color: ${({
    $done,
    theme: {
      palette: { success, grey },
    },
  }: IStyledBatchProps): string => ($done ? success.light : grey[300])};
`;

export const StepStatus = styled.div`
  display: flex;
  padding: ${spacing(2)} ${spacing(1)};
  gap: ${spacing(1)};
`;
