import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';

interface IMainActionProps {
  $bottom: boolean;
}

export const MainAction = styled.section`
  ${({ $bottom }: IMainActionProps): CssStyles | false =>
    $bottom &&
    css`
      margin-top: auto;
    `}
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
