import { Tooltip as MaterialToolTip, TooltipProps } from '@mui/material';
import theme from '../../../themes';

const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <MaterialToolTip
      arrow
      {...props}
      PopperProps={{
        sx: {
          zIndex: 1,
          '& .MuiTooltip-tooltip': {
            background: theme.palette.warning.main,
            width: '100%',
            margin: '12px 0',
          },
          '& .MuiTooltip-arrow': {
            color: theme.palette.warning.main,
          },
        },
      }}
    >
      {children}
    </MaterialToolTip>
  );
};

export default Tooltip;
