import { ITheme } from '../interfaces/theme.interface';

export const spacing =
  (space: number) =>
  ({ theme }: ITheme): string =>
    `${theme.spacing(space)}`;

export const borderRadius =
  (space = 1) =>
  ({ theme }: ITheme): string =>
    `${space * Number(theme.shape.borderRadius)}px`;
