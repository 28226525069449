import { Box } from '@mui/material';
import styled from 'styled-components';
import { spacing } from '../../../themes/_helpers';

export const Container = styled(Box)`
  min-width: ${spacing(22.5)};
  width: ${spacing(22.5)};
  text-align: center;
  margin: 0 auto;
`;
