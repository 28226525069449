import { TypeBackground } from '@mui/material';
import {
  IconComponent,
  IconDisabledStar,
  IconGhostAnnoyed,
  IconHourglass,
  IconStar,
  IconGhost,
} from '../components/atoms/Icons';
import { AppRoute, ROUTES } from '../constants/routes.const';
import { IReward, ValidationStep } from '../interfaces/rewards.interface';

export const isStepDone = (step: ValidationStep): boolean => {
  if (typeof step.value === 'boolean') return step.value;
  return step.value === step.total_required;
};

export const isBatchCompleted = (currentbatch: number, step: ValidationStep): boolean => {
  return currentbatch <= step.value;
};

export interface IButtonCardContent {
  id: number;
  about: string;
  description: string;
  route: AppRoute;
  icon: IconComponent;
  background: keyof TypeBackground;
  disabled: boolean;
  subText?: string;
}

const pendingAttrs = {
  about: 'Revise seus dados de pagamento para garantir já a sua recompensa!',
  subText: 'recompensa não resgatada <span>⚠️</span>',
};

export const buildButtonCard = ({
  status,
  id,
  name: description,
  subtitle,
}: IReward): IButtonCardContent => {
  const images = {
    available: IconStar,
    in_progress: IconHourglass,
    withdrawn: IconDisabledStar,
    expired: IconGhost,
    pending: IconGhostAnnoyed,
  };

  const icon = images[status];
  const route = `${ROUTES.CONTA_RECOMPENSA}/${id}`;
  const about = status === 'pending' ? pendingAttrs.about : subtitle;
  const background = status === 'pending' ? 'errorLight' : 'white';
  const disabled = status === 'expired';
  const subText = status === 'pending' ? pendingAttrs.subText : undefined;

  return {
    id,
    about,
    description,
    route,
    icon,
    subText,
    background,
    disabled,
  };
};
