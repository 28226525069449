import * as S from './styles';

export const InputShow = ({ firstArg, secondArg }: { firstArg: string; secondArg: string }) => {
  return (
    <S.CustomBox>
      <S.CustomTypography>{firstArg}</S.CustomTypography>
      <S.CustomTypography>{secondArg}</S.CustomTypography>
    </S.CustomBox>
  );
};
