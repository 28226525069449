import { Box, Typography } from '@mui/material';
import { SplashImage } from '../../atoms';
import { ISplashImageProps } from '../../atoms/SplashImage';

export interface IHeadingContentProps {
  img: ISplashImageProps;
  title: string;
  subtitle?: string;
}

type IHeadingProps = IHeadingContentProps & { children?: React.ReactNode };

const Heading = ({ img, title, subtitle, children }: IHeadingProps): JSX.Element => (
  <Box component="section">
    <SplashImage paddingY={4} {...img} />
    <Box paddingX={3} marginBottom={4}>
      <Typography
        role="heading"
        align="center"
        variant="h2"
        gutterBottom
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {subtitle && (
        <Typography
          variant="p5"
          role="heading"
          align="center"
          dangerouslySetInnerHTML={{ __html: subtitle }}
          paragraph
        />
      )}
      {children}
    </Box>
  </Box>
);

export default Heading;
