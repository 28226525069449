import { EventData } from '../interfaces/events.interface';
import { AnalyticsService } from '../modules/temAnalytics';

const logEvent = (eventData: EventData, payload?: Record<string, unknown>): void => {
  AnalyticsService.instance.emitEvent({
    name: eventData.action,
    params: payload,
  });
};

const Events = { logEvent };

export default Events;
