import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: calc(50vw - 40px);
  transform: translateY(-50%);
`;

const Circular = styled(CircularProgress)`
  width: 80px !important;
  height: 80px !important;

  & svg {
    width: 80px;
    height: 80px;
  }
`;

const Spinner: React.FC = () => (
  <Container data-testid="spinner">
    <Circular />
  </Container>
);

export default Spinner;
