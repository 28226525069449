import axios, { AxiosError } from 'axios';
import { authRequired, buildAuth } from '../utils/authorization';
import { getEnv } from '../utils/env';
import { formatUrl } from '../utils/format';

const defaultHeaders = {
  'Content-Type': 'application/json',
  key: getEnv('REACT_APP_PARTNER_KEY'),
  'Accept-Language': 'pt-br',
};
const baseURL = getEnv('REACT_APP_API_HOST');

const instance = axios.create({
  baseURL,
  headers: defaultHeaders,
});

instance.interceptors.request.use(
  config => {
    if (config.url && authRequired(config.url)) {
      const Authorization = buildAuth();
      config.headers = { ...config.headers, Authorization } as Record<string, unknown>;
    }
    return config;
  },
  error => Promise.reject(error),
);

export async function getData<T>(url: string, params?: string[], headers?: unknown): Promise<T> {
  try {
    const response = await instance.get<T>(formatUrl(url, params), { headers });
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    throw err.response?.data;
  }
}

export async function postData<T>(url: string, data: unknown, headers?: unknown): Promise<T> {
  try {
    const response = await instance.post<T>(url, data, { headers });
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    throw err.response?.data;
  }
}

export async function putData<T>(url: string, data: unknown, headers?: unknown): Promise<T> {
  try {
    const response = await instance.put<T>(url, data, { headers });
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    throw err.response?.data;
  }
}
export async function patchData<T>(url: string, data: unknown, headers?: unknown): Promise<T> {
  try {
    const response = await instance.patch<T>(url, data, { headers });
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    throw err.response?.data;
  }
}
