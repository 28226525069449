/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as braze from '@braze/web-sdk';
import { getEnv } from '../../utils/env';

const BRAZE_API_KEY = getEnv('REACT_APP_BRAZE_API_KEY');
const BRAZE_SDK_ENDPOINT = getEnv('REACT_APP_BRAZE_SDK_ENDPOINT');

export class BrazeService {
  /* ---------------------------- Singleton Instance --------------------------- */
  static #instance: BrazeService;

  static get instance(): BrazeService {
    if (!BrazeService.#instance) {
      BrazeService.#instance = new BrazeService();
    }
    return BrazeService.#instance;
  }

  /* ------------------------------- Properties ------------------------------- */
  #app?: typeof braze;

  get app(): typeof braze | undefined {
    return this.#app;
  }

  /* ------------------------------- Methods ------------------------------- */
  async initializeApp(): Promise<void> {
    if (!this.#app && braze) {
      braze.initialize(BRAZE_API_KEY, {
        baseUrl: BRAZE_SDK_ENDPOINT,
      });
      braze.automaticallyShowInAppMessages();
      braze.openSession();
      this.#app = braze;
    }
  }
}
