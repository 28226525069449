import { EVENTS } from '../../constants/events.const';
import Events from '../../services/events.service';

export default {
  click: (): void => {
    Events.logEvent({
      action: EVENTS.ACTIONS.SPECIAL_PLAN_PURCHASE_CLICK,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    });
  },
  success: (): void => {
    Events.logEvent({
      action: EVENTS.ACTIONS.SPECIAL_PLAN_PURCHASE_SUCCESS,
      category: EVENTS.CATEGORIES.SPECIAL_PLAN_PURCHASE,
      label: EVENTS.LABELS.SUCCESS,
    });
  },
  fail: (): void => {
    Events.logEvent({
      action: EVENTS.ACTIONS.SPECIAL_PLAN_PURCHASE_FAIL,
      category: EVENTS.CATEGORIES.SPECIAL_PLAN_PURCHASE,
      label: EVENTS.LABELS.FAIL,
    });
  },

  pageView: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.PURCHASE_SPECIAL_PLAN_VIEW,
      category: EVENTS.CATEGORIES.PAGE,
      label: EVENTS.LABELS.SUCCESS,
    }),
};
