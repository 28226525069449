import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '../../components/atoms';
import PlanTransactionTemplate from '../../components/templates/PlanTransaction';
import { QueryKeys } from '../../constants/queryKeys.const';
import { ROUTES } from '../../constants/routes.const';
import { STORAGE } from '../../constants/storage.const';
import { DriverContext } from '../../contexts/DriverContext';
import { Payments } from '../../contexts/PlanPurchaseContext';
import { useGetCard } from '../../hooks/useGetCard';
import { usePlanDetail } from '../../hooks/usePlanDetail';
import { IPurchasePlanParams, usePurchasePlan } from '../../hooks/usePurchasePlan';
import { ICreditCard } from '../../interfaces/creditCard.interface';
import { IErrorNormalized } from '../../interfaces/error.interface';
import { IPlan } from '../../interfaces/plan.interface';
import { ILoggedUser } from '../../interfaces/userData.interface';
import { getCustomError, isGivenCustomError } from '../../services/error.service';
import { storeItem } from '../../utils/localStorage';
import { messageRoute } from '../../utils/route';
import { ERROR_DETAILS } from '../../utils/serverErrorHandler';
import { MessageTypes } from '../InfoView/messages.const';
import BuyPlanEvents from './purchaseSpecialPlan.events';

type Voucher = {
  voucher: string;
};

interface IParams {
  planId: string;
  voucher: string;
}

const PurchaseSpecialPlan = (): JSX.Element => {
  const { region } = useContext(DriverContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { pending_address } = queryClient.getQueryData<ILoggedUser>(
    QueryKeys.userData,
  ) as ILoggedUser;

  const onErrorPlanDetail = (err: IErrorNormalized): void => {
    if (isGivenCustomError(err, ERROR_DETAILS.PLAN_NOT_FOUND)) {
      navigate(messageRoute(MessageTypes.ERROR_PLAN_NOT_FOUND));
    } else {
      navigate(messageRoute(MessageTypes.ERROR_SERVER));
    }
  };

  const { data, isLoading: isPlanDetailLoading } = usePlanDetail({ onError: onErrorPlanDetail });

  const { data: creditCard, error: errorGetCard } = useGetCard({
    staleTime: 0,
    cacheTime: 0,
  });

  const { voucher: voucherParam, planId } = useParams<keyof IParams>();

  const paymentMethod = voucherParam ? Payments.externalPayment : Payments.creditCard;

  const selectedPlan = data;

  const onError = (err: IErrorNormalized): void => {
    BuyPlanEvents.fail();
    if (isGivenCustomError(err, ERROR_DETAILS.INVALID_COUPON)) {
      navigate(messageRoute(MessageTypes.ERROR_INVALID_COUPON));
      return;
    }
    if (isGivenCustomError(err, ERROR_DETAILS.BPV_PLAN_ACTIVE)) {
      navigate(messageRoute(MessageTypes.BPV_PLAN_ACTIVE));
      return;
    }
    navigate(messageRoute(MessageTypes.ERROR_SERVER));
  };

  const onSuccess = (): void => {
    BuyPlanEvents.success();
    queryClient.refetchQueries(QueryKeys.userPlan);
    storeItem(STORAGE.CURRENT_PLAN_PAYMENT_TYPE, paymentMethod);
    const successRoute = pending_address
      ? MessageTypes.PLAN_PURCHASE_PENDING_ADDRESS
      : MessageTypes.SUCCESS_SIGNEDUP;
    navigate(messageRoute(successRoute));
  };

  const { error, reset, mutate, isLoading } = usePurchasePlan({ onError, onSuccess });

  const alertErrorMessage = getCustomError(error);

  useEffect(() => BuyPlanEvents.pageView(), []);

  if (
    !voucherParam &&
    isGivenCustomError(errorGetCard, ERROR_DETAILS.USER_DONT_HAVE_ANY_CREDIT_CARDS)
  ) {
    return <Navigate to={ROUTES.CADASTRO_CARTAO} state={{ planId }} />;
  }

  const changeCard = (): void => navigate(ROUTES.CADASTRO_CARTAO, { state: { planId } });

  const onSubmit = ({ voucher }: Voucher): void => {
    const purchasePayload: IPurchasePlanParams = {
      plan_id: (selectedPlan as IPlan).id.toString(),
      payment_type: paymentMethod,
      credit_card: creditCard?.number,
      auto_renew: false,
      region,
    };
    BuyPlanEvents.click();
    mutate({ ...purchasePayload, voucher });
  };

  if (isPlanDetailLoading) return <Spinner />;

  const isCreditCard = (): ICreditCard | undefined => (!voucherParam ? creditCard : undefined);

  return (
    <PlanTransactionTemplate
      changeCard={changeCard}
      onSubmit={onSubmit}
      isSubmitting={isLoading}
      handleCloseAlert={reset}
      alertErrorMessage={alertErrorMessage}
      voucherPlaceholder="Voucher de pagamento"
      isLoading={isLoading}
      selectedPlan={selectedPlan as IPlan}
      disabled={(): boolean => false}
      voucherValue={voucherParam}
      creditCard={isCreditCard()}
    />
  );
};

export default PurchaseSpecialPlan;
