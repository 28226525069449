/* eslint-disable @typescript-eslint/no-unsafe-call */
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { useEffect, useRef } from 'react';
import { IAdyenData } from '../../../interfaces/encryptedCard.interface';
import { getEnv } from '../../../utils/env';
import * as S from './style';

const REACT_APP_ADYEN_TOYAPP_CLIENT_KEY = getEnv('REACT_APP_ADYEN_TOYAPP_CLIENT_KEY');
const ADYEN_ENV = getEnv('REACT_APP_ADYEN_ENV');

interface IEncryptedCardProps {
  handleChange: (state: IAdyenData, component: unknown) => void;
}

const CreditCard: React.FC<IEncryptedCardProps> = ({ handleChange }) => {
  const card = useRef<HTMLDivElement>(null);

  const onError = (_err: unknown): void => undefined;

  useEffect(() => {
    const getAdyenComponent = async (): Promise<void> => {
      if (!card.current) return;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const checkout = await AdyenCheckout({
          clientKey: REACT_APP_ADYEN_TOYAPP_CLIENT_KEY,
          onChange: handleChange,
          locale: 'pt-BR',
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
            },
          },
          environment: ADYEN_ENV,
          showPayButton: false,
          onError,
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        checkout.create('card').mount(card.current);
      } catch (error) {
        onError(error);
      }
    };

    getAdyenComponent();
  }, [handleChange]);

  return <S.Card ref={card} />;
};

export default CreditCard;
