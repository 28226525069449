import { CardContent, Typography } from '@mui/material';
import ListBenefits from '../ListBenefits';
import { IPlan } from '../../../interfaces/plan.interface';
import { breakLines } from '../../../utils/format';

import * as S from './style';

interface PlanDetailsProps {
  plan: IPlan;
}

const PlanDetails: React.FC<PlanDetailsProps> = ({
  plan: {
    name,
    description,
    value: { formated_price },
  },
}) => (
  <S.CardFull>
    <CardContent>
      <Typography fontWeight="bold">{name}</Typography>
      <ListBenefits benefits={breakLines(description)} />

      <Typography fontWeight="bold" color="primary">
        {formated_price}
      </Typography>
    </CardContent>
  </S.CardFull>
);

export default PlanDetails;
