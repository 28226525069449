import * as S from './style';

interface IBulletListProps {
  items: string[];
  itemsSpaced?: boolean;
}

const BulletList: React.FC<IBulletListProps> = ({ items, itemsSpaced = false }) => {
  return (
    <S.List $itemsSpaced={itemsSpaced}>
      {items.map(item => (
        <S.ListItem key={item}>{item}</S.ListItem>
      ))}
    </S.List>
  );
};

export default BulletList;
