import { ReactElement } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate } from 'react-router-dom';
import { QueryKeys } from '../../constants/queryKeys.const';
import { PlanShapes, SignedPlanStatus } from '../../interfaces/plan.interface';
import { IUser } from '../../interfaces/userData.interface';
import { MessageTypes } from '../../pages/InfoView/messages.const';
import { getPlanStatus } from '../../utils/plan';
import { messageRoute } from '../../utils/route';

interface IGuard {
  [key: string]: unknown;
  children: ReactElement;
}

const { SIGNED_OTHER, NOT_SIGNED, SIGNED_PARTNER } = SignedPlanStatus;

type RedirectRoutesShape = {
  [key in SignedPlanStatus]: string | null;
};

export const redirectRoutes: RedirectRoutesShape = {
  [SIGNED_OTHER]: messageRoute(MessageTypes.SUCCESS_HAS_PLAN),
  [NOT_SIGNED]: messageRoute(MessageTypes.ERROR_HASNT_PLAN),
  [SIGNED_PARTNER]: null,
};

export const PlanActiveRoute = ({ children }: IGuard): JSX.Element => {
  const queryClient = useQueryClient();
  const userPlan = queryClient.getQueryData<PlanShapes>(QueryKeys.userPlan);
  const { pending_address } = queryClient.getQueryData<IUser>(QueryKeys.userData) || {};
  const userPlanStatus = getPlanStatus(userPlan as PlanShapes);

  const onPendingAddress = messageRoute(MessageTypes.UNLOCK_BIKE_PENDING_ADDRESS);
  const redirectTo = pending_address ? onPendingAddress : redirectRoutes[userPlanStatus];

  if (!redirectTo) return children;

  return <Navigate to={redirectTo} />;
};

export default PlanActiveRoute;
