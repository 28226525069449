import { EVENTS } from '../../../constants/events.const';
import Events from '../../../services/events.service';

export default {
  termsClick: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.TYC_CLICK,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    }),
  termsRead: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.TYC_READ,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    }),
};
