import styled from 'styled-components';
import { Snackbar } from '@mui/material';
import { ITheme } from '../../../interfaces/theme.interface';

export type SnackType = 'success' | 'warning' | 'error';

interface ISnackProps extends ITheme {
  type: SnackType;
}

const backgroundColor = ({ theme, type }: ISnackProps): string => theme.palette[type].main;
const color = ({ theme }: ISnackProps): string => theme.palette.common.white;
const paddingVertical = ({ theme }: ISnackProps): string => `${theme.spacing(1.75)}`;
const paddinghorizontal = ({ theme }: ISnackProps): string => `${theme.spacing(2.25)}`;
const borderRadius = ({ theme }: ISnackProps): string => `${theme.shape.borderRadius}px`;
const spacing = ({ theme }: ISnackProps): string => `${theme.spacing(2)}`;

export const SnackMessage = styled(Snackbar)`
  background-color: ${backgroundColor};
  color: ${color};
  padding: ${paddingVertical} ${paddinghorizontal};
  border-radius: ${borderRadius};
  bottom: ${spacing};
  left: ${spacing};
  right: ${spacing};
`;
