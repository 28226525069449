import styled from 'styled-components';

interface ISplashImageProps {
  minHeight?: string;
}

export const MainAction = styled.section`
  margin-top: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SplashImage = styled.img`
  min-height: ${({ minHeight }: ISplashImageProps): string => minHeight ?? '20px'};
  width: 180px;
  margin: 0 auto;
  margin-bottom: 36px;
  display: block;
`;
