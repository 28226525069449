import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';

interface ListProps extends ITheme {
  $itemsSpaced: boolean;
}

export const List = styled.ul`
  padding-left: 20px;

  ${({ $itemsSpaced }: ListProps): CssStyles | false =>
    $itemsSpaced &&
    css`
      & *:not(:first-child) {
        margin-top: 12px;
      }
    `}
`;

export const ListItem = styled.li`
  list-style: disc;
`;
