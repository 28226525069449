import { CREDIT_CARD } from '../API/tempartner';
import { QueryKeys } from '../constants/queryKeys.const';
import { ICreditCard, ICreditCardResponse } from '../interfaces/creditCard.interface';
import { UseBuildCustomQuery } from '../interfaces/query.interface';
import { buildGetRequest } from '../utils/request';
import { useGuardedQuery } from './useGuardedQuery';

export const useGetCard: UseBuildCustomQuery<ICreditCard> = (options = {}) => {
  const url = CREDIT_CARD.GET;

  const request = buildGetRequest<ICreditCardResponse, ICreditCard>({
    url,
    select: ({ results: [creditCard] }) => creditCard,
  });

  return useGuardedQuery<ICreditCard>([QueryKeys.getCard], request, options);
};
