import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { messageRoute } from '../utils/route';
import { MessageTypes } from '../pages/InfoView/messages.const';
import { ILoggedUser, IUser } from '../interfaces/userData.interface';
import { QueryKeys } from '../constants/queryKeys.const';
import { REGIONS } from '../constants/regions.const';

type Penalty = () => void;

export const usePenalty = (): Penalty => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<IUser>(QueryKeys.userData) as ILoggedUser;

  if (user?.location.region === REGIONS.RJ) {
    return (): void =>
      navigate(messageRoute(MessageTypes.USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_RIO));
  }

  const external = {
    Tembici: messageRoute(MessageTypes.USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_APP_TEMBICI),
    'bike-itau': messageRoute(MessageTypes.USER_HAS_A_PENALTY_START_TRIP_NO_HEADER),
  };
  const externalAppRoute =
    external[user?.location?.external_app ?? 'bike-itau'] ?? external['bike-itau'];

  const navigateTo = externalAppRoute;

  return (): void => navigate(navigateTo);
};
