import { IUserTripInfo } from '../contexts/DriverContext';
import { IAccess } from './access.interface';

export enum UserStatus {
  loggedIn = 'logged_in',
  needsToLogin = 'needs_to_login',
  needsToSignup = 'needs_to_signup',
}
export enum PenaltyStatus {
  without_penalty = 'without_penalty',
  penalty = 'penalty',
  warning_active_auto_migration = 'warning_active_auto_migration',
  warning_inactive_auto_migration = 'warning_inactive_auto_migration',
}

export enum BPV_STATUS {
  WAITING_DOCUMENTS = 'WAITING_DOCUMENTS',
  REGISTRATION_REJECTED = 'REGISTRATION_REJECTED',
  REGISTRATION_APPROVED = 'REGISTRATION_APPROVED',
  SCHEDULED_TRIP = 'SCHEDULED_TRIP',
  CHECKED_IN = 'CHECKED_IN',
  WAITING_REGISTRATION_APPROVE = 'WAITING_REGISTRATION_APPROVE',
  PENDING_SIGNUP = 'PENDING_SIGNUP',
  AWAITING_BOOKING = 'AWAITING_BOOKING',
  TRAVELING = 'TRAVELING',
  TRAVEL_EXPIRED = 'TRAVEL_EXPIRED',
}

export type IBPVStatus = keyof typeof BPV_STATUS;

export interface ILoggedUser extends IUserResponseBase, IAccess {
  user_status: UserStatus.loggedIn;
}

export interface ISignedUser extends IUserResponseBase {
  user_status: UserStatus.needsToLogin;
}

export interface INewUser extends IUserResponseBase {
  user_status: UserStatus.needsToSignup;
  cpf: string;
  phone: string;
}

interface IBenefits {
  icon: string;
  content: string;
}

export interface ILocationConfig {
  qr_code_enabled: boolean;
  external_payment_enabled: boolean;
  booking_link?: string;
  access_link?: string;
  hub_and_self_service_v2_enabled?: boolean;
}

export interface ILocationDetailsInfo {
  title: string;
  benefits: IBenefits[];
  subTitle: string;
}

export interface ILocationUser {
  location_slug: string;
  external_app: 'Tembici' | 'bike-itau';
  region: string;
  qrcode_countdown: number;
  location_config: ILocationConfig;
  terms: string;
  infos: ILocationDetailsInfo;
}

interface IUserContact {
  tembici_email: string;
  partner_email: string;
  bpv_email?: string;
  phone: string;
}
interface IUserResponseBase {
  fit_for_bpv: boolean;
  bpv_state?: IBPVStatus;
  user_status: UserStatus;
  system_id?: string;
  location: ILocationUser;
  pending_address?: boolean;
  has_pending_benefit: boolean;
  trip: IUserTripInfo;
  user_contact: IUserContact;
  penalty_status?: PenaltyStatus;
  has_card: boolean;

  [key: string]: unknown;
}

export type IUser = ILoggedUser | ISignedUser | INewUser;
