import styled from 'styled-components';
import { ListItemText } from '@mui/material';
import { fontColor } from '../../../themes/_colors';
import { spacing } from '../../../themes/_helpers';

export const ItemText = styled(ListItemText)`
  margin: 0px;
`;

export const Item = styled.div`
  display: flex;
  gap: ${spacing(0.5)};
  padding: 0px;
  color: ${fontColor};
`;
