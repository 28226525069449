import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

const StyledP1 = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const StyledP2 = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25px;
`;

const StyledP3 = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 22.5px;
  letter-spacing: 0.25px;
`;

const StyledP4 = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const StyledP5 = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const StyledP6 = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

type Variants = 'p1' | 'p2' | 'p3' | 'p4' | 'p5' | 'p6';
interface IVariant {
  variant?: Variants;
}

const Paragraphs = {
  p1: StyledP1,
  p2: StyledP2,
  p3: StyledP3,
  p4: StyledP4,
  p5: StyledP5,
  p6: StyledP6,
};

const Paragraph: React.FC<Omit<TypographyProps, 'variant'> & IVariant> = ({
  children,
  variant = 'p1',
  ...props
}) => {
  const SelectedParagraph = Paragraphs[variant];

  return (
    <SelectedParagraph variant="body1" {...props}>
      {children}
    </SelectedParagraph>
  );
};

export default Paragraph;
