/* eslint-disable @typescript-eslint/no-shadow */
import { IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useController } from 'react-hook-form';
import TextMask from 'react-text-mask';
import * as S from './styles';
import { FieldComponent, UsePassword } from './types';

const usePasswordField: UsePassword = originalType => {
  const [pswdVisible, setPswdVisible] = useState(false);
  const toggleVisibility = (): void => setPswdVisible(v => !v);

  const isPasswordField = originalType === 'password';
  const passwordFieldType = pswdVisible ? 'text' : 'password';

  const type = isPasswordField ? passwordFieldType : originalType;

  const PasswordInput = (): JSX.Element => (
    <InputAdornment position="end" data-testid="passwordIcon">
      <IconButton aria-label="toggle password visibility" onClick={toggleVisibility}>
        {pswdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </InputAdornment>
  );

  return {
    type,
    toggleVisibility,
    pswdVisible,
    isPasswordField,
    PasswordInput,
  };
};

const InputHookForm: FieldComponent = ({ ...props }) => {
  const {
    type: originalType = 'text',
    label = '',
    stickyLabel = '',
    control,
    name,
    mask,
    persistedError,
    inputMode,
    variant,
    onBlur,
    onChange,
    onFocus,
    InputProps = {},
    ...rest
  } = props;
  const { field, fieldState } = useController({ control, name });
  const { error, isTouched } = fieldState;
  const { value, ref } = field;

  const { type, isPasswordField, PasswordInput } = usePasswordField(originalType);

  if (isPasswordField) InputProps.endAdornment = <PasswordInput />;

  const hasError = !!persistedError || (isTouched && !!error);

  const helperText = persistedError || (isTouched ? error?.message : '');

  const baseFieldProps = {
    inputMode,
    variant,
    name,
    type,
    role: 'input',
    inputRef: ref,
    InputProps,
    error: hasError,
    helperText,
    autoComplete: 'off',
    ...rest,
  };
  const baseFieldMaskProps = {
    inputMode,
    variant,
    name,
    type,
    role: 'input',
    InputProps,
    error: hasError,
    helperText,
    autoComplete: 'off',
    ...rest,
  };

  const handlers = {
    onBlur: onBlur ?? field.onBlur,
    onChange: onChange ?? field.onChange,
    onFocus,
  };

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      {mask ? (
        <TextMask
          id={name}
          value={value}
          mask={mask}
          disabled={rest.disabled}
          {...handlers}
          render={(ref, props) => (
            <TextField
              label={stickyLabel ?? ''}
              inputRef={ref}
              {...props}
              {...baseFieldMaskProps}
            />
          )}
        />
      ) : (
        <>
          <S.Input
            label={stickyLabel ?? ''}
            id={name}
            value={value}
            {...handlers}
            {...baseFieldProps}
          />
        </>
      )}
    </>
  );
};

export default InputHookForm;
