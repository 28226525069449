import { ListItem, IconButton } from '@mui/material';
import styled from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';

const color = ({ theme }: ITheme): string => theme.palette.grey[900];

export const CustomItem = styled(ListItem)`
  height: 56px;
  color: ${color};
`;

export const CustomIcon = styled(IconButton)`
  color: ${color};
`;
