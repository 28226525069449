import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { InputState } from 'react-input-mask';
import * as Yup from 'yup';
import { HEADER_TITLE } from '../../../constants/header.const';
import { ROUTES } from '../../../constants/routes.const';
import { ICreditCard } from '../../../interfaces/creditCard.interface';
import { IPlan } from '../../../interfaces/plan.interface';
import { MessageTypes } from '../../../pages/InfoView/messages.const';
import { formatToCurrency } from '../../../utils/format';
import { Body, Button, Main, Spinner } from '../../atoms';
import { CreditCardField } from '../../molecules';
import { Alert, NavBar, PlanDetails } from '../../organisms';
import { CONTENT } from './planTransaction.content';
import * as S from './style';

interface IVoucherForm {
  voucher: string;
}

type Disabled = {
  isSubmitting: boolean;
  isValid: boolean;
  isDirty: boolean;
};

interface IPlanTransactionProps {
  onSubmit: (values: IVoucherForm) => void;
  selectedPlan: IPlan;
  creditCard?: ICreditCard;
  isLoading: boolean;
  isSubmitting: boolean;
  disabled: (params: Disabled) => boolean;
  alertErrorMessage: string | null;
  voucherPlaceholder: string;
  handleCloseAlert: () => void;
  changeCard: () => void;
  voucherValue?: string;
  showVoucherField?: boolean;
}

const PlanTransactionTemplate = ({
  onSubmit,
  selectedPlan,
  creditCard,
  isLoading,
  isSubmitting,
  disabled,
  alertErrorMessage,
  changeCard,
  handleCloseAlert,
  voucherPlaceholder,
  voucherValue,
  showVoucherField = false,
}: IPlanTransactionProps): JSX.Element => {
  const steps = {
    current: 3,
    total: 4,
  };

  const defaultValues = {
    voucher: voucherValue || '',
  };

  const VOUCHER_LENGTH = 24;
  const mask = '*'.repeat(VOUCHER_LENGTH);

  const validationSchema = Yup.object().shape({ string: Yup.string().uppercase().trim() });

  const beforeMaskedValueChange = (newState: InputState): InputState => {
    return { ...newState, value: newState.value.toUpperCase().trim() };
  };

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = form;

  if (isLoading) return <Spinner />;

  return (
    <Body>
      <NavBar
        backRoute={voucherValue ? ROUTES.HUB : ROUTES.PLANOS}
        title={HEADER_TITLE.PURCHASE_PLAN}
        steps={steps}
      />
      <Main paddingTop>
        {!!selectedPlan && <PlanDetails plan={selectedPlan} />}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box marginTop={4}>
            {!!creditCard && (
              <Box marginBottom={3}>
                <CreditCardField onClick={changeCard} creditCard={creditCard} />
              </Box>
            )}
            {showVoucherField && !voucherValue && (
              <S.Field
                control={control}
                id="voucher"
                name="voucher"
                variant="outlined"
                data-testid="inputVoucher"
                label={voucherPlaceholder}
                type="text"
                mask={mask}
                beforeMaskedValueChange={beforeMaskedValueChange}
              />
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" marginBottom={2}>
            <span>{CONTENT.planPriceLabel}</span>
            {!!selectedPlan && (
              <span style={{ fontWeight: 600 }}>{formatToCurrency(selectedPlan.value.price)}</span>
            )}
          </Box>
          <Button
            disabled={disabled({ isSubmitting, isValid, isDirty })}
            isSubmitting={isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
          >
            {CONTENT.buttonAction}
          </Button>
        </form>
      </Main>
      <Alert
        message={alertErrorMessage}
        messageType={MessageTypes.ERROR_SERVER}
        open={!!alertErrorMessage}
        handleClose={handleCloseAlert}
      />
    </Body>
  );
};

export default PlanTransactionTemplate;
