import { create } from 'zustand';

type GlobalLoading = {
  isGlobalLoading: boolean;
};

type Action = {
  setIsGlobalLoading: (isGlobalLoading: GlobalLoading['isGlobalLoading']) => void;
};

export const useGlobalLoadingStore = create<GlobalLoading & Action>(set => ({
  isGlobalLoading: false,
  setIsGlobalLoading: isGlobalLoading => set({ isGlobalLoading }),
}));
