import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';

interface IMainProps {
  $paddingTop?: boolean;
  $gap?: number;
}

export const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  ${({ $paddingTop }: IMainProps): CssStyles | false =>
    !$paddingTop &&
    css`
      padding-top: 0;
    `}
  ${({ $gap }: IMainProps): CssStyles | false =>
    !!$gap &&
    css`
      gap: ${$gap}px;
    `}
`;
