import { Typography } from '@mui/material';
import { TitleProps } from './types';

export const Title = ({ text, variant = 'lg' }: TitleProps): JSX.Element => {
  const fontSize = variant === 'lg' ? '20.8px' : '18px';
  const lineHeight = variant === 'lg' ? '29.12px' : '25.5px';
  const letterSpacing = variant === 'lg' ? '0.156px' : '0.027px';

  return (
    <Typography
      fontWeight={600}
      textAlign="center"
      fontSize={fontSize}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
    >
      {text}
    </Typography>
  );
};
