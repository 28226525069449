import { EVENTS } from '../../constants/events.const';
import Events from '../../services/events.service';

export default {
  pageView: (): void => {
    Events.logEvent({
      action: EVENTS.ACTIONS.PLAN_ENTREGADOR_VIEW,
      category: EVENTS.CATEGORIES.PENALTY,
      label: EVENTS.LABELS.SUCCESS,
    });
  },
  clickOk: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.PLAN_ENTREGADOR_OK,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    }),
  clickCancel: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.PLAN_ENTREGADOR_CANCELAR_CARTAO,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    }),
};
