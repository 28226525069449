export const CONTENT = {
  terms: 'Aceito os ',
  openTerms: 'Termos e Condições',
  terms2: '',
  acceptTerms: 'Concordo',
} as const;

export const BPV_CONTENT = {
  terms: 'Li e concordo com os ',
  openTerms: 'Termos e Condições de Uso. ',
  terms2: 'Os termos estarão disponíveis para consulta no app.',
  acceptTerms: 'Concordo',
} as const;
