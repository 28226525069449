import { Typography } from '@mui/material';
import * as S from './style';

interface IStepStatus {
  done: boolean;
  label: string;
}

const StepStatus = ({ done, label }: IStepStatus): JSX.Element => (
  <S.StepStatus>
    <S.CheckIcon $done={done} />
    <Typography lineHeight={1.8}>{label}</Typography>
  </S.StepStatus>
);

export default StepStatus;
