import {
  EmitEventParams,
  EmitEventType,
  EVENT_ALL_DESTINATIONS,
  AnalyticsDestination,
  EventParams,
  AnalyticsServiceInterface,
  EventType,
  GetLatLong,
  SetUserParams,
  SetUserPropertyParams,
} from '../../temCore';
import { BrazeAnalyticsService } from '../core/braze';
import { FirebaseAnalyticsService } from '../core/firebase';
import { AnalyticsSetup } from '../core/types';

export class AnalyticsService<T extends string> implements AnalyticsServiceInterface<T> {
  /* ------------------------------- Properties ------------------------------- */
  /**
   * Function to get latitude and longitude as tuple result like [lat, long]
   */
  getLatLong: GetLatLong;

  static isInitialized = false;

  /* ---------------------------- Singleton Instance --------------------------- */
  static #instance: AnalyticsServiceInterface<string>;

  static get instance(): AnalyticsServiceInterface<string> {
    if (!AnalyticsService.#instance) {
      AnalyticsService.#instance = new AnalyticsService();
    }
    return AnalyticsService.#instance;
  }

  /* ------------------------------- Constructor ------------------------------ */
  private constructor() {
    this.getLatLong = () => [undefined, undefined];
  }

  /* ---------------------------- Auxiliary Methods ---------------------------- */
  #mapParams = (type?: EventType, params?: EventParams): EventParams => {
    const [lat, long] = this.getLatLong();
    const mappedParams = {
      ...params,
      ...(!!type && {
        event_type: type,
      }),
      latitude: lat ?? '',
      longitude: long ?? '',
    };
    return mappedParams;
  };

  /**
   * Function to create service instance
   */
  static readonly setup = ({ firebaseApp, brazeApp, getLatLong }: AnalyticsSetup): void => {
    if (getLatLong) AnalyticsService.instance.getLatLong = getLatLong;
    if (firebaseApp) FirebaseAnalyticsService.setup(firebaseApp);
    if (brazeApp) BrazeAnalyticsService.setup(brazeApp);
    AnalyticsService.isInitialized = true;
  };

  /* ------------------------ Interface Implementations ----------------------- */
  /**
   * Method to emit analytic events based on called params
   * @param name string name oFicou f event to emit
   * @param type the main type of event emittion
   * @param destinations targets to emit event
   * @param params params to send for destinations
   */
  emitEvent: EmitEventType<T> = ({ name, type, destinations, params }: EmitEventParams<T>) => {
    // Format and add default params
    const mappedParams = this.#mapParams(type, params);

    // Emit events to all destinations of default destinations
    (destinations ?? EVENT_ALL_DESTINATIONS).forEach(destination => {
      switch (destination) {
        case AnalyticsDestination.FIREBASE:
          FirebaseAnalyticsService.instance?.emitEvent(name, mappedParams);
          break;

        case AnalyticsDestination.BRAZE:
          BrazeAnalyticsService.instance?.emitEvent(name, mappedParams);
          break;

        default:
          break;
      }
    });
  };

  /**
   *  Sets the user and properties to Analytics
   * @param user
   * @param destinations targets to set the user
   */
  setUser = ({ user, destinations }: SetUserParams): void => {
    (destinations ?? EVENT_ALL_DESTINATIONS).forEach(destination => {
      switch (destination) {
        case AnalyticsDestination.FIREBASE:
          FirebaseAnalyticsService.instance?.setUser(user);
          break;

        case AnalyticsDestination.BRAZE:
          BrazeAnalyticsService.instance?.setUser(user);
          break;

        default:
          break;
      }
    });
  };

  /**
   * Sets a custom user property
   */
  setUserProperty = ({ key, value, destinations }: SetUserPropertyParams): void => {
    if (!!key && value) {
      (destinations ?? EVENT_ALL_DESTINATIONS).forEach(destination => {
        switch (destination) {
          case AnalyticsDestination.FIREBASE:
            FirebaseAnalyticsService.instance?.setUserProperty(key, value);
            break;

          case AnalyticsDestination.BRAZE:
            BrazeAnalyticsService.instance?.setUserProperty(key, value);
            break;

          default:
            break;
        }
      });
    }
  };
}
