import { useNavigate } from 'react-router-dom';
import { messageRoute } from '../utils/route';
import { MessageTypes } from '../pages/InfoView/messages.const';

type Block = () => void;

export const useBlock = (): Block => {
  const navigate = useNavigate();
  const blockDefaultRoute = messageRoute(MessageTypes.USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED);
  return (): void => navigate(blockDefaultRoute);
};
