import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

const StyledF1 = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const StyledF2 = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const StyledF3 = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.15px;
`;

type Variants = 'f1' | 'f2' | 'f3';
interface IVariant {
  variant?: Variants;
}

const Forms = {
  f1: StyledF1,
  f2: StyledF2,
  f3: StyledF3,
};

const Form: React.FC<Omit<TypographyProps, 'variant'> & IVariant> = ({
  children,
  variant = 'f1',
  ...props
}) => {
  const SelectedForm = Forms[variant];

  return (
    <SelectedForm variant="body1" {...props}>
      {children}
    </SelectedForm>
  );
};

export default Form;
