import { useNavigate } from 'react-router-dom';
import { MessageTypes } from '../pages/InfoView/messages.const';
import { messageRoute } from '../utils/route';

type Guard = () => void;

export const useGuard = (): Guard => {
  const navigate = useNavigate();
  return (): void => navigate(messageRoute(MessageTypes.ERROR_CIRCUIT_BREAKER_OPENED));
};
