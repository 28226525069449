import styled from 'styled-components';
import { errorColor, primaryColor } from '../../../themes/_colors';

export const Card = styled.div`
  & .adyen-checkout__spinner--large {
    border-color: ${primaryColor};
    border-top-color: transparent;
  }

  & .adyen-checkout__input {
    border: none;
    border-bottom: 1px solid #b9c4c9;
    border-radius: 0;

    &.adyen-checkout__input--error {
      border-bottom: 1px solid ${errorColor};
    }
  }

  & .adyen-checkout__error-text {
    color: ${errorColor};
  }

  & .adyen-checkout__label__text--error {
    color: ${errorColor};
  }

  & .adyen-checkout__field.adyen-checkout__field--cardNumber,
  & .adyen-checkout__card__exp-cvc.adyen-checkout__field-wrapper,
  & .adyen-checkout__field.adyen-checkout__card__holderName {
    margin-bottom: 40px;
  }
`;
