import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query';
import { IErrorNormalized } from '../interfaces/error.interface';
import { isBlockError, isCatastrophicError, isPenaltyError } from '../services/error.service';
import { useGuard } from './useGuard';
import { usePenalty } from './usePenalty';
import { useBlock } from './useBlock';

type GuardedQuery = <TFn = unknown, TData = TFn, TKey extends QueryKey = QueryKey>(
  qk: TKey,
  fn: QueryFunction<TFn, TKey>,
  options?: Omit<UseQueryOptions<TFn, IErrorNormalized, TData, TKey>, 'queryKey'>,
) => UseQueryResult<TData, IErrorNormalized>;

export const useGuardedQuery: GuardedQuery = (qk, fn, options = {}) => {
  const { onError, onSettled } = options;
  const guard = useGuard();
  const penalty = usePenalty();
  const block = useBlock();

  type Settled = typeof onSettled;
  type Error = typeof onError;

  const guardedError: Error = error => {
    if (isCatastrophicError(error)) return guard();
    if (isPenaltyError(error)) return penalty();
    if (isBlockError(error)) return block();
    if (onError) onError(error);
  };

  const guardedSettled: Settled = (data, error) => {
    if (isCatastrophicError(error)) return guard();
    if (isPenaltyError(error)) return penalty();
    if (isBlockError(error)) return block();
    if (onSettled) onSettled(data, error);
  };

  return useQuery(qk, fn, { ...options, onSettled: guardedSettled, onError: guardedError });
};

type GuardedInfinityQuery = <TFn = unknown, TData = TFn, TKey extends QueryKey = QueryKey>(
  qk: TKey,
  fn: QueryFunction<TFn, TKey>,
  options?: Omit<UseInfiniteQueryOptions<TFn, IErrorNormalized, TData, TFn, TKey>, 'queryKey'>,
) => UseInfiniteQueryResult<TData, IErrorNormalized>;

export const useGuardedInfiniteQuery: GuardedInfinityQuery = (qk, fn, options = {}) => {
  const { onError, onSettled } = options;
  const guard = useGuard();
  const penalty = usePenalty();
  const block = useBlock();

  type Settled = typeof onSettled;
  type Error = typeof onError;

  const guardedError: Error = error => {
    if (isCatastrophicError(error)) return guard();
    if (isPenaltyError(error)) return penalty();
    if (isBlockError(error)) return block();
    if (onError) onError(error);
  };

  const guardedSettled: Settled = (data, error) => {
    if (isCatastrophicError(error)) return guard();
    if (isPenaltyError(error)) return penalty();
    if (isBlockError(error)) return block();
    if (onSettled) onSettled(data, error);
  };

  return useInfiniteQuery(qk, fn, { ...options, onSettled: guardedSettled, onError: guardedError });
};
