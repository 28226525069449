export interface StartTraceParams {
  name: string;
  destination: MonitorDestination;
}

export enum MonitorDestination {
  FIREBASE,
}

export interface MonitorServiceInterface {
  startTrace: (startTraceParams: StartTraceParams) => void;
}
