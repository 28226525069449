import { createContext, useCallback, useEffect, useState } from 'react';
import { getRemoteConfigs } from '../utils/firebase';

export interface IRemoteConfigContextData {
  remoteConfigs: IRemoteConfigValues;
}

export interface IRemoteConfigValues {
  // keeping so typescript does not break
  ifood_show_unlock_bike?: string;
  ifood_show_lock_bike?: string;
  support_buffer_slug?: string;
}

const remoteConfigContextValues = {
  remoteConfigs: {
    // keeping so typescript does not break
    ifood_show_unlock_bike: '',
    ifood_show_lock_bike: '',
    support_buffer_slug: '',
  },
};

export const RemoteConfigContext =
  createContext<IRemoteConfigContextData>(remoteConfigContextValues);

export const useRemoteConfig = (): IRemoteConfigContextData => {
  const [remoteConfigs, setRemoteConfigs] = useState<IRemoteConfigValues>(
    remoteConfigContextValues.remoteConfigs,
  );

  const loadRemoteConfigs = useCallback(async () => {
    try {
      const result = await getRemoteConfigs();
      if (!result) {
        setRemoteConfigs({});
        return;
      }
      const keys: IRemoteConfigValues = Object.keys(result).reduce((seed, key) => {
        return Object.assign(seed, { [key]: result[key].asString() });
      }, {} as IRemoteConfigValues);
      setRemoteConfigs(keys);
    } catch (error) {
      setRemoteConfigs({});
    }
  }, []);

  useEffect(() => {
    loadRemoteConfigs();
  }, [loadRemoteConfigs]);

  return {
    remoteConfigs,
  };
};
