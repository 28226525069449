import { Typography } from '@mui/material';
import styled from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';
import { greyColor } from '../../../themes/_colors';

interface IStyledBatchProps extends ITheme {
  $done: boolean;
}
export const Batch = styled.div<IStyledBatchProps>`
  border-radius: 2px;
  height: 4px;
  background-color: ${({
    $done,
    theme: {
      palette: { success, grey },
    },
  }: IStyledBatchProps): string => ($done ? success.light : grey[300])};
  flex-grow: 1;
`;

export const Trips = styled(Typography)`
  color: ${greyColor(500)};
`;
