import { create } from 'zustand';

type State = {
  photoFrontDocument: string;
  photoBackDocument: string;
  photoProofResidenceDocument: string;
  isIdentificationDocumentComplete: boolean;
  isProofResidenceComplete: boolean;
  setIsIdentificationDocumentComplete: (isIdentificationDocumentComplete: boolean) => void;
  setIsProofResidenceComplete: (isProofResidenceComplete: boolean) => void;
  setPhotoFrontDocument: (photoFrontDocument: string) => void;
  setPhotoBackDocument: (photoBackDocument: string) => void;
  setPhotoProofResidenceDocument: (photoBackDocument: string) => void;
};

export const useSignupDocumentStore = create<State>(set => ({
  photoFrontDocument: '',
  photoBackDocument: '',
  photoProofResidenceDocument: '',
  isIdentificationDocumentComplete: false,
  isProofResidenceComplete: false,
  setIsProofResidenceComplete: isProofResidenceComplete => set({ isProofResidenceComplete }),
  setIsIdentificationDocumentComplete: isIdentificationDocumentComplete =>
    set({ isIdentificationDocumentComplete }),
  setPhotoFrontDocument: photoFrontDocument => set({ photoFrontDocument }),
  setPhotoBackDocument: photoBackDocument => set({ photoBackDocument }),
  setPhotoProofResidenceDocument: photoProofResidenceDocument =>
    set({ photoProofResidenceDocument }),
}));
