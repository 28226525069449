import { Divider, SwipeableDrawer } from '@mui/material';
import styled from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';
import { greyColor } from '../../../themes/_colors';

export const BoxButton = styled.div`
  height: 100px;
  background-color: ${greyColor(100)};
  box-shadow: 0px 2px 8px ${greyColor(500)};
  display: flex;
  flex-direction: column;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  justify-content: space-evenly;
`;

export const CustomDivider = styled(Divider)`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
`;

export const ParagraphCircleIcon = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }: ITheme): string => theme.palette.primary.main};
  border-radius: 50%;
`;

export const PushIcon = styled.div`
  width: 40px;
  height: 5px;
  border-radius: 2.5px;
  background-color: ${greyColor(300)};
`;

export const Swipeable = styled(SwipeableDrawer)`
  & .MuiDrawer-paperAnchorBottom {
    max-height: 92%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
`;

export const Container = styled.div`
  width: auto;
  height: 90%;
`;
