export interface IPlan {
  selected?: boolean;
  system?: number;
  id: number;
  name: string;
  description: string;
  short_description: string;
  value: {
    currency: string;
    symbol: string;
    price: number;
    formated_price: string;
    is_taxable?: boolean;
    save?: string;
    period?: string;
  };
  duration?: {
    start_time: string;
    end_time: string;
  };
  is_renewable?: boolean;
  payment_types?: [];
  location_reference: string;
}

export type WithoutPlan = Record<never, never>;

export type OtherPlan = null;

export type PlanShapes = IPlan | WithoutPlan | OtherPlan;

export enum SignedPlanStatus {
  SIGNED_PARTNER = 'SIGNED_PARTNER',
  SIGNED_OTHER = 'SIGNED_OTHER',
  NOT_SIGNED = 'NOT_SIGNED',
}

export interface IPLanResponse {
  attributes: unknown[];
  categories: Category[];
  client_references: string[];
  currency: string;
  description: string;
  id: number;
  location_references: string[];
  product_type: number;
  payment_methods?: string[];
  product_sub_type: number;
  price: string;
  price_label: string;
  segmentations: unknown[];
  short_description: string;
  slug: string;
  title: string;
  external_reference: number;
  start_time: string;
  end_time: string;
  auto_renew: boolean;
  subscription_id: number;
  status: string;
  price_label_info?: PriceLabelInfo;
}

export interface IPlansResponse {
  results: IPLanResponse[];
}

interface Category {
  activation_rule: string;
  color_type: number;
  description: string;
  id: number;
  image_url?: string;
  parent_id?: number;
  short_description: string;
  title: string;
  color_type_description?: string;
}

interface PriceLabelInfo {
  label: string;
  formatted_value: string;
  formatted_label_value: string;
  frequency: string | null;
  interval: number;
}
