import {
  Analytics,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';
import { AnalyticsInternalServiceInterface, EmitEventInternalType } from '../types';
import { User } from '../../../temCore';

export class FirebaseAnalyticsService implements AnalyticsInternalServiceInterface {
  /* ---------------------------- Singleton Instance --------------------------- */
  static #instance: FirebaseAnalyticsService;

  static get instance(): FirebaseAnalyticsService {
    return FirebaseAnalyticsService.#instance;
  }

  /* ------------------------------- Properties ------------------------------- */
  #analytics: Analytics;

  /* ------------------------------- Constructor ------------------------------ */
  constructor(firebaseApp: FirebaseApp) {
    this.#analytics = getAnalytics(firebaseApp);
  }

  static readonly setup = (firebaseApp: FirebaseApp): void => {
    if (!FirebaseAnalyticsService.#instance) {
      FirebaseAnalyticsService.#instance = new FirebaseAnalyticsService(firebaseApp);
    }
  };

  /* ------------------------------- Methods ------------------------------- */
  /**
   * Emits an event to Firebase
   * @param name name of event to emit
   * @param params params to send for destination
   */
  emitEvent: EmitEventInternalType = (name, params) => {
    if (this.#analytics) {
      logEvent(this.#analytics, name, params);
    }
  };

  /**
   *  Sets the user and properties to Firebase Analytics
   * @param user
   */
  setUser = (user: User): void => {
    if (user.id) {
      setUserId(this.#analytics, user.id);
    }
    if (user.email) {
      setUserProperties(this.#analytics, {
        email: user.email,
      });
    }
  };

  /**
   * Sets a custom user property
   */
  setUserProperty = (key: string, value: string): void => {
    if (!!key && !!value) {
      setUserProperties(this.#analytics, {
        [key]: value,
      });
    }
  };
}
