import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate } from 'react-router-dom';
import { QueryKeys } from '../../constants/queryKeys.const';
import { ROUTES } from '../../constants/routes.const';
import { PlanPurchaseContext } from '../../contexts/PlanPurchaseContext';
import { useGetCard } from '../../hooks/useGetCard';
import { PlanShapes } from '../../interfaces/plan.interface';
import { isGivenCustomError } from '../../services/error.service';
import { shouldMigratePlan, withoutPlan } from '../../utils/plan';
import { ERROR_DETAILS } from '../../utils/serverErrorHandler';
import { Spinner } from '../atoms';

interface IGuard {
  [key: string]: unknown;
}
/**
 * handles the route for checkout process
 * validates if user can checkout and which route to redirect
 * redirects to missing step if user is not ready to checkout
 * redirects to select card if user is missing card
 */
export const ProxyPurchaseRoute = (): JSX.Element => {
  const queryClient = useQueryClient();
  const userPlan = queryClient.getQueryData<PlanShapes>(QueryKeys.userPlan) as PlanShapes;

  const { selectedPlan, paymentMethod, isCreditCard } = useContext(PlanPurchaseContext);

  const { error, isLoading } = useGetCard({ enabled: isCreditCard(), staleTime: 0 });

  if (isLoading) return <Spinner />;

  const canUserCheckout = paymentMethod && selectedPlan;

  if (isCreditCard() && isGivenCustomError(error, ERROR_DETAILS.USER_DONT_HAVE_ANY_CREDIT_CARDS)) {
    return <Navigate to={ROUTES.CADASTRO_CARTAO} />;
  }

  if (canUserCheckout) {
    const route = shouldMigratePlan(userPlan) ? ROUTES.MIGRAR_PLANO : ROUTES.COMPRA_NOVO_PLANO;

    return <Navigate to={route} />;
  }

  const userMissingStepRoute = withoutPlan(userPlan) ? ROUTES.PLANOS : ROUTES.SELECAO_PAGAMENTO;

  return <Navigate to={userMissingStepRoute} />;
};

export default ProxyPurchaseRoute;
