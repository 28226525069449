import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

const StyledH1 = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

const StyledH2 = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 25.5px;
  letter-spacing: 0.0015em;
`;

const StyledH3 = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.15px;
`;

const StyledH4 = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const StyledH5 = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.65px;
`;

type Variants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
interface IVariant {
  variant?: Variants;
}

const Titles = {
  h1: StyledH1,
  h2: StyledH2,
  h3: StyledH3,
  h4: StyledH4,
  h5: StyledH5,
};

const Title: React.FC<Omit<TypographyProps, 'variant'> & IVariant> = ({
  children,
  variant = 'h1',
  ...props
}) => {
  const SelectedTitle = Titles[variant];

  return (
    <SelectedTitle variant={variant} {...props}>
      {children}
    </SelectedTitle>
  );
};

export default Title;
