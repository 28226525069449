import { Box, Typography, styled } from '@mui/material';

export const CustomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.grey[100],
  height: '52px',
  padding: '1rem',
  borderRadius: '0.5rem',
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
}));
