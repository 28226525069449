export const APP_VERSION = {
  default: 'default',
  modular: 'modular',
} as const;

export type AppVersionKeys = keyof typeof APP_VERSION;
export type AppVersion = typeof APP_VERSION[AppVersionKeys];

export const APP_VARIANT = {
  default: 'default',
} as const;

type AppVariantKeys = keyof typeof APP_VARIANT;
export type AppVariant = typeof APP_VARIANT[AppVariantKeys];
