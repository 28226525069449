import * as S from './style';

interface IMainProps {
  paddingTop?: boolean;
  gap?: number;
}

const Main: React.FC<IMainProps> = ({ children, paddingTop, gap }) => (
  <S.Main $paddingTop={paddingTop} $gap={gap}>
    {children}
  </S.Main>
);

export default Main;
