import { getRemoteConfig, fetchAndActivate, getAll, Value } from 'firebase/remote-config';
import { getEnv } from './env';

const fetchTime = getEnv('REACT_APP_FIREBASE_REMOTE_CONFIG_FETCH_TIME');
export async function getRemoteConfigs(): Promise<Record<string, Value> | null> {
  try {
    const remoteConfig = getRemoteConfig();
    if (!remoteConfig) return null;
    remoteConfig.settings.minimumFetchIntervalMillis = fetchTime ? Number(fetchTime) : 0;
    await fetchAndActivate(remoteConfig);
    return getAll(remoteConfig);
  } catch (error) {
    return null;
  }
}
