import { FirebasePerformance, getPerformance, PerformanceTrace, trace } from 'firebase/performance';
import { FirebaseApp } from 'firebase/app';
import { MonitorInternalServiceInterface } from '../types';
import { Start } from './types';

export class FirebaseMonitorService implements MonitorInternalServiceInterface {
  /* ------------------------------- Constructor ------------------------------ */
  constructor(name: string, firebaseApp: FirebaseApp) {
    this.#performance = getPerformance(firebaseApp);
    this.#trace = trace(this.#performance, name);
  }

  /* ------------------------------- Properties ------------------------------- */
  #performance: FirebasePerformance;

  #trace: PerformanceTrace;

  /* ------------------------------- Methods ------------------------------- */

  start = (): Start => {
    this.#trace.start();
    return {
      stop: () => {
        this.#trace.stop();
      },
    };
  };
}
