import { FirebaseApp, initializeApp as firebaseInitializeApp } from 'firebase/app';
import { getEnv } from '../../utils/env';

const firebaseConfig = {
  apiKey: getEnv('REACT_APP_FIREBASE_APIKEY'),
  authDomain: getEnv('REACT_APP_FIREBASE_AUTHDOMAIN'),
  projectId: getEnv('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getEnv('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnv('REACT_APP_FIREBASE_MESSAGE_SENDER_ID'),
  appId: getEnv('REACT_APP_FIREBASE_APP_ID'),
  measurementId: getEnv('REACT_APP_GOOGLE_ANALYTICS'),
};
export class FirebaseService {
  /* ---------------------------- Singleton Instance --------------------------- */
  static #instance: FirebaseService;

  static get instance(): FirebaseService {
    if (!FirebaseService.#instance) {
      FirebaseService.#instance = new FirebaseService();
    }
    return FirebaseService.#instance;
  }

  /* ------------------------------- Properties ------------------------------- */
  #app?: FirebaseApp;

  /**
   * @returns the FirebaseApp instance
   * @throws Error if initializeApp was not called.
   */
  get app(): FirebaseApp | undefined {
    return this.#app;
  }

  initializeApp(): void {
    this.#app = firebaseInitializeApp(firebaseConfig);
  }
}
