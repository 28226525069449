import { NavigateNext } from '@mui/icons-material';
import { Box, TypeBackground } from '@mui/material';
import { IconHelp } from '../../atoms/Icons';

import * as S from './style';

export type CardVariant = 'primary' | 'wideHigh' | 'wideLow';

export interface IButtonCardProps {
  handler: () => void;
  description: string;
  subText?: string;
  background?: keyof TypeBackground;
  about?: string;
  icon?: React.FC;
  disabled?: boolean;
  testId?: string;
  variant: CardVariant;
  externalIcon?: string;
}

const ButtonCard: React.FC<IButtonCardProps> = ({
  description,
  subText,
  about = '',
  background = 'white',
  disabled = false,
  handler,
  testId,
  icon: Icon = IconHelp,
  variant,
  externalIcon,
}) => {
  return (
    <S.ButtonCard
      data-testid={testId}
      disabled={disabled}
      background={background}
      onClick={handler}
      variantstyle={variant}
    >
      <S.Container variantstyle={variant}>
        <S.IconContainer variantstyle={variant}>
          {externalIcon ? (
            <S.ExternalIcon data-testid="externalIcon" src={externalIcon} />
          ) : (
            <Icon />
          )}
        </S.IconContainer>
        <S.MainContent variantstyle={variant}>
          <S.TextBox variantstyle={variant}>
            <S.Text variantstyle={variant} dangerouslySetInnerHTML={{ __html: description }} />
            {subText && (
              <S.SubText variantstyle={variant} dangerouslySetInnerHTML={{ __html: subText }} />
            )}
          </S.TextBox>
          {variant === 'wideHigh' && (
            <Box display="flex" justifyContent="end" alignItems="end">
              <NavigateNext color={background === 'red' ? 'inherit' : 'primary'} />
            </Box>
          )}
          {variant === 'wideLow' && <S.About dangerouslySetInnerHTML={{ __html: about }} />}
          {variant === 'primary' && (
            <NavigateNext color={background === 'red' ? 'inherit' : 'primary'} />
          )}
        </S.MainContent>
        {variant === 'wideLow' && (
          <NavigateNext color={background === 'red' ? 'inherit' : 'primary'} />
        )}
      </S.Container>
    </S.ButtonCard>
  );
};

export default ButtonCard;
