import { EVENTS } from '../constants/events.const';
import { IError } from '../interfaces/error.interface';
import { EventData } from '../interfaces/events.interface';
import { errorService } from '../services/error.service';
import Events from '../services/events.service';
import { getData, patchData, postData, putData } from '../services/request.service';
import { Optional, UnknownObject } from './interface';

type EventSuccess = Optional<EventData, 'label'>;
type EventError = Optional<EventData, 'label' | 'category'>;

interface IEvents {
  success: EventSuccess;
  error: EventError;
}

type Select<TRawData, TData> = (data: TRawData) => TData;

type BaseRequest<TRawData, TData> = {
  url: string;
  headers?: UnknownObject;
  select?: Select<TRawData, TData>;
};

type PostRequest<TRawData, TData> = BaseRequest<TRawData, TData> & {
  data?: unknown;
};

type PutRequest<TRawData, TData> = PostRequest<TRawData, TData>;

type PatchRequest<TRawData, TData> = PostRequest<TRawData, TData>;

type GetRequest<TRawData, TData> = BaseRequest<TRawData, TData> & {
  params?: string[];
};

type Request<T> = <D>(data?: D) => Promise<T>;

const handleError = (error: unknown, events?: IEvents): never => {
  if (events?.error) {
    const message = JSON.stringify(error);
    Events.logEvent(
      {
        category: `message: ${message}`,
        label: EVENTS.LABELS.FAIL,
        ...events.error,
      },
      { message },
    );
  }
  throw errorService(error as IError);
};

const handleSuccess = (events?: IEvents): void => {
  if (events?.success) {
    Events.logEvent({
      label: EVENTS.LABELS.SUCCESS,
      ...events.success,
    });
  }
};

/**
 * Configura a chamada de Post com tratativas de erro e emissão de Eventos
 *
 * @param request objeto com configurações da requisição
 * @param events objeto com dados de eventos em caso de sucesso e fracasso da requisição
 *
 * @returns função responsável por requisição Post
 *
 * @typeParam TRawData - formato da resposta do post
 * @typeParam TData - formato tratado da resposta
 *
 */
export const buildPostRequest = <TRawData = unknown, TData = TRawData>(
  request: PostRequest<TRawData, TData>,
  events?: IEvents,
): Request<TData> => {
  return async <TPostData>(data?: TPostData): Promise<TData> => {
    try {
      const response = await postData<TRawData>(request.url, data ?? request.data, request.headers);
      handleSuccess(events);
      return request?.select ? request.select(response) : (response as unknown as TData);
    } catch (error) {
      return handleError(error, events);
    }
  };
};

/**
 * Configura a chamada de Get com tratativas de erro e emissão de Eventos
 *
 * @param request objeto com configurações da requisição
 * @param events objeto com dados de eventos em caso de sucesso e fracasso da requisição
 *
 * @returns função responsável por requisição Get
 *
 * @typeParam TRawData - formato da resposta do get
 * @typeParam TData - formato tratado da resposta
 *
 */
export const buildGetRequest = <TRawData = unknown, TData = TRawData>(
  request: GetRequest<TRawData, TData>,
  events?: IEvents,
): Request<TData> => {
  return async (): Promise<TData> => {
    try {
      const response = await getData<TRawData>(request.url, request.params, request.headers);
      handleSuccess(events);
      return request?.select ? request.select(response) : (response as unknown as TData);
    } catch (error) {
      return handleError(error, events);
    }
  };
};

/**
 * Configura a chamada de Put com tratativas de erro e emissão de Eventos
 *
 * @param request objeto com configurações da requisição
 * @param events objeto com dados de eventos em caso de sucesso e fracasso da requisição
 *
 * @returns função responsável por requisição Put
 *
 * @typeParam TRawData - formato da resposta do put
 * @typeParam TData - formato tratado da resposta
 *
 */
export const buildPutRequest = <TRawData = unknown, TData = TRawData>(
  request: PutRequest<TRawData, TData>,
  events?: IEvents,
): Request<TData> => {
  return async <TPutData>(data?: TPutData): Promise<TData> => {
    try {
      const response = await putData<TRawData>(request.url, data ?? request.data, request.headers);
      handleSuccess(events);
      return request?.select ? request.select(response) : (response as unknown as TData);
    } catch (error) {
      return handleError(error, events);
    }
  };
};

/**
 * Configura a chamada de Patch com tratativas de erro e emissão de Eventos
 *
 * @param request objeto com configurações da requisição
 * @param events objeto com dados de eventos em caso de sucesso e fracasso da requisição
 *
 * @returns função responsável por requisição Put
 *
 * @typeParam TRawData - formato da resposta do put
 * @typeParam TData - formato tratado da resposta
 *
 */
export const buildPatchRequest = <TRawData = unknown, TData = TRawData>(
  request: PatchRequest<TRawData, TData>,
  events?: IEvents,
): Request<TData> => {
  return async <TPatchData>(data?: TPatchData): Promise<TData> => {
    try {
      const response = await patchData<TRawData>(
        request.url,
        data ?? request.data,
        request.headers,
      );
      handleSuccess(events);
      return request?.select ? request.select(response) : (response as unknown as TData);
    } catch (error) {
      return handleError(error, events);
    }
  };
};
