import { ButtonProps } from '@mui/material';
import * as S from './style';

interface IButton {
  isSubmitting?: boolean;
}

const Button: React.FC<IButton & ButtonProps> = ({ children, isSubmitting = false, ...rest }) => (
  <S.Button fullWidth variant="contained" {...rest}>
    {isSubmitting ? <S.CircularProgress size={20} /> : children}
  </S.Button>
);

export default Button;
