import { isMobile } from 'react-device-detect';
import { BPV_PAGE_TITLE_PREFIX, EventActionTypes } from '../../constants/events.const';
import {
  cartaoAprovado,
  pedalada,
  opsSomethingWrong,
  cardError,
  erro,
  bike,
  lastAttempt,
  waitTime,
  googlePlay,
  qrCodeFail,
  pendingAddress,
  dailyExceeded,
  loadingBike,
  circuitBreak,
  onRoute,
  cardMachine,
  driver,
  cardDenied,
  proof,
  sendSuccess,
  uploadError,
  messageNotSend,
  driverPose,
} from '../../constants/images.const';

import { AppRoute, LINKS, ROUTES } from '../../constants/routes.const';
import { URLS } from '../../constants/urls.const';

export type MessageKeys = keyof typeof MessageTypes;

export enum MessageTypes {
  ERROR_SERVER = 'ERROR_SERVER',
  ERROR_CIRCUIT_BREAKER_OPENED = 'ERROR_CIRCUIT_BREAKER_OPENED',
  ERROR_CREDIT_CARD = 'ERROR_CREDIT_CARD',
  ERROR_INVALID_COUPON = 'ERROR_INVALID_COUPON',
  SUCCESS_HAS_PLAN = 'SUCCESS_HAS_PLAN',
  SUCCESS_ACCOUNT = 'SUCCESS_ACCOUNT',
  SUCCESS_SIGNEDUP = 'SUCCESS_SIGNEDUP',
  SUCCESS_QRCODE = 'SUCCESS_QRCODE',
  ERROR_HAS_QRCODE = 'ERROR_HAS_QRCODE',
  ERROR_GENERATE_QRCODE = 'ERROR_GENERATE_QRCODE',
  ERROR_HASNT_PLAN = 'ERROR_HASNT_PLAN',
  ERROR_PLAN_NOT_FOUND = 'ERROR_PLAN_NOT_FOUND',
  SUCCESS_SIGNATURE = 'SUCCESS_SIGNATURE',
  ALREADY_HAS_PLAN = 'ALREADY_HAS_PLAN',
  ERROR_HELPCENTER = 'ERROR_HELPCENTER',
  SUCCESS_CREDITCARD_UPDATE = 'SUCCESS_CREDITCARD_UPDATE',
  ERROR_SIGNEDUP = 'ERROR_SIGNEDUP',
  DOWNLOAD_APP = 15, // TODO temporary while migrating app, back refs this number
  ERROR_BOOTSTRAP = 'ERROR_BOOTSTRAP',
  SUCCESS_MIGRATE_PLAN = 'SUCCESS_MIGRATE_PLAN',
  HAS_TO_MIGRATE_PLAN = 'HAS_TO_MIGRATE_PLAN',
  LAST_RECOVERY_PSWD_TRY = 'LAST_RECOVERY_PSWD_TRY',
  LAST_CHANGE_PSWD_TRY = 'LAST_CHANGE_PSWD_TRY',
  ATTEMPTS_EXPIRED = 'ATTEMPTS_EXPIRED',
  SUCCESS_PASWD_CHANGE = 'SUCCESS_PASWD_CHANGE',
  ATTEMPTS_EXPIRED_CHANGE_PSWD_ACCOUNT = 'ATTEMPTS_EXPIRED_CHANGE_PSWD_ACCOUNT',
  SUSPENDED_ERROR_QRCODE = 'SUSPENDED_ERROR_QRCODE',
  NO_PLAN_ERROR_QRCODE = 'NO_PLAN_ERROR_QRCODE',
  SIGNUP_ACTIVATION = 'SIGNUP_ACTIVATION',
  SUCCESS_ADDRESS = 'SUCCESS_ADDRESS',
  PLAN_PURCHASE_PENDING_ADDRESS = 'PLAN_PURCHASE_PENDING_ADDRESS',
  SUCCCESS_UNLOCK_BIKE_V2 = 'SUCCCESS_UNLOCK_BIKE_V2',
  UNLOCK_BIKE_PENDING_ADDRESS = 'UNLOCK_BIKE_PENDING_ADDRESS',
  UNLOCK_BIKE_DAILY_TRIPS_EXCEEDED = 'UNLOCK_BIKE_DAILY_TRIPS_EXCEEDED',
  UNLOCK_BIKE_DAILY_TRIPS_EXCEEDED_OTHERS = 'UNLOCK_BIKE_DAILY_TRIPS_EXCEEDED_OTHERS',
  LOCK_BIKE_SUCCESS = 'LOCK_BIKE_SUCCESS',
  QR_CODE_NOT_FOUND = 'QR_CODE_NOT_FOUND',
  UNLOCK_BIKE_ERROR = 'UNLOCK_BIKE_ERROR',
  LOCK_BIKE_ERROR_SUPPORT_BUFFER_RANGE = 'LOCK_BIKE_ERROR_SUPPORT_BUFFER_RANGE',
  UNLOCK_BIKE_ERROR_SUPPORT_BUFFER_RANGE = 'UNLOCK_BIKE_ERROR_SUPPORT_BUFFER_RANGE',
  BIKE_ALREADY_IN_TRIP = 'BIKE_ALREADY_IN_TRIP',
  USER_ALREADY_IN_TRIP = 'USER_ALREADY_IN_TRIP',
  BIKE_NOT_FROM_SUPPORT_BUFFER = 'BIKE_NOT_FROM_SUPPORT_BUFFER',
  USER_HAS_A_PENALTY_START_TRIP = 'USER_HAS_A_PENALTY_START_TRIP',
  USER_HAS_A_PENALTY_START_TRIP_APP_TEMBICI = 'USER_HAS_A_PENALTY_START_TRIP_APP_TEMBICI',
  USER_HAS_A_PENALTY_START_TRIP_RIO = 'USER_HAS_A_PENALTY_START_TRIP_RIO',
  CANCEL_AUTO_MIGRATION_SUCCESS = 'CANCEL_AUTO_MIGRATION_SUCCESS',
  USER_HAS_A_PENALTY_START_TRIP_NO_HEADER = 'USER_HAS_A_PENALTY_START_TRIP_NO_HEADER',
  USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_APP_TEMBICI = 'USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_APP_TEMBICI',
  USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_RIO = 'USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_RIO',
  USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED = 'USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED',
  DOWNLOAD_APP_TEMBICI = 'DOWNLOAD_APP_TEMBICI',
  BPV_PLAN_ACTIVE = 'BPV_PLAN_ACTIVE',
  BEFORE_REGISTER = 'BEFORE_REGISTER',
  DOCUMENT_FRONT = 'DOCUMENT_FRONT',
  DOCUMENT_BACK = 'DOCUMENT_BACK',
  BIKE_CARE = 'BIKE_CARE',
  PROOF_OF_RESIDENCE = 'PROOF_OF_RESIDENCE',
  SEND_SUCCESS_DOCUMENT = 'SEND_SUCCESS_DOCUMENT',
  ERROR_SERVER_DOCUMENT = 'ERROR_SERVER_DOCUMENT',
  ERROR_SERVER_TOKEN = 'ERROR_SERVER_TOKEN',
  SIGNUP_SERVER = 'SIGNUP_SERVER',
  SIGNUP_CHECK_YOUR_EMAIL = 'SIGNUP_CHECK_YOUR_EMAIL',
  SIGNUP_CHECK_YOUR_EMAIL_RESEND = 'SIGNUP_CHECK_YOUR_EMAIL_RESEND',
  SMS_EXCEED_RETRIES = 'SMS_EXCEED_RETRIES',
  ERROR_SERVER_SMS = 'ERROR_SERVER_SMS',
}

interface IButtonData {
  buttonPlaceholder?: string;
  buttonText?: string;
  buttonSecondaryText?: string;
}
interface IRouteData {
  defaultRoute?: string;
  secondaryRoute?: string;
  externalRoute?: string;
  externalSecondaryRoute?: string;
  redirectHost?: boolean;
  backRoute?: AppRoute;
}

interface IEvents {
  primaryEvent?: EventActionTypes;
  secondaryEvent?: EventActionTypes;
  viewEvent?: EventActionTypes;
  pageTitle?: string;
  swipeableOpenEventName?: string;
}
interface IContentData {
  header?: string;
  description: string;
  title: string;
  subtitle?: string;
  defaultMessage: string;
  image?: string;
  titleTop?: string;
  hasSwipeable?: boolean;
  defaultMessageAlign?: 'right' | 'left' | 'center' | 'inherit' | 'justify';
  buttonVariant?: 'outlined' | 'text' | 'contained';
}

interface IMessageData extends IButtonData, IRouteData, IContentData, IEvents {}

type MessageDataCollection = { [V in MessageTypes]: IMessageData };

export const MESSAGE_DATA: MessageDataCollection = {
  ERROR_SERVER: {
    description: 'Erro',
    title: 'Algo deu errado',
    defaultMessage: 'Não foi possível realizar esta ação.<br/>Por favor, tente novamente',
    defaultRoute: ROUTES.HUB,
    image: erro,
    buttonText: 'Tentar novamente',
  },
  BPV_PLAN_ACTIVE: {
    header: 'Ifood Pedal',
    backRoute: ROUTES.HUB,
    description: 'Erro',
    title: 'Parece que você já tem um plano ativo',
    defaultMessage:
      'Para pedalar com o iFood Pedal, você deve terminar o seu plano atual do Bike pra Você.',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Entendi',
  },
  ERROR_CIRCUIT_BREAKER_OPENED: {
    description: 'Erro',
    title: 'Opa! Algo deu errado',
    defaultMessage:
      'Ocorreu um erro em nossos servidores.<br/>Aguarde alguns minutos e tente novamente.',
    externalRoute: URLS.EXIT_WEBVIEW,
    image: circuitBreak,
    buttonText: 'Tentar mais tarde',
  },
  ERROR_CREDIT_CARD: {
    description: 'Erro',
    title: 'Algo deu errado',
    defaultMessage: 'Não conseguimos concluir sua assinatura com este cartão. Tente com outro.',
    defaultRoute: ROUTES.RESUMO_COMPRA,
    image: cardError,
    buttonText: 'Tentar outro cartão',
  },
  SUCCESS_HAS_PLAN: {
    description: 'Sucesso',
    title: 'Opa, você já tem um plano ativo',
    defaultMessage:
      'Caso queira assinar outro plano, é necessário realizar o cancelamento do plano atual.',
    externalRoute: URLS.CANCEL_CURRENT_PLAN_TYPEFORM,
    secondaryRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Cancelar Plano Atual',
    buttonSecondaryText: 'Agora Não',
  },
  SUCCESS_ACCOUNT: {
    description: 'Sucesso',
    title: 'Sua conta foi criada!',
    defaultMessage: 'Agora é só finalizar sua compra para começar a pedalar por aí.',
    defaultRoute: ROUTES.CADASTRO_CARTAO,
    image: pedalada,
    buttonText: 'Continuar',
  },
  SUCCESS_SIGNEDUP: {
    description: 'Sucesso',
    title: 'Tudo certo com seu pagamento!',
    defaultMessage: 'A sua assinatura já está valendo. O próximo passo é desbloquear sua bike.',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: cartaoAprovado,
    buttonText: 'Desbloquear bike',
  },
  SUCCESS_QRCODE: {
    description: 'Sucesso',
    title: 'Boa pedalada!',
    defaultMessage: 'Você já pode sair pedalando por ai.<br/>Boas entregas!',
    defaultRoute: ROUTES.HUB,
    image: pedalada,
    redirectHost: true,
    buttonText: 'A bike foi desbloqueada!',
  },
  ERROR_HAS_QRCODE: {
    description: 'Erro',
    title: 'Você já está em uma viagem',
    defaultMessage: 'Para desbloquear uma nova bike, finalize a viagem atual.',
    defaultRoute: ROUTES.HUB,
    image: pedalada,
    buttonText: 'Ok, entendi',
  },
  ERROR_GENERATE_QRCODE: {
    description: 'Erro',
    title: 'Não foi possível gerar um código QR',
    defaultMessage: '',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Tentar novamente',
  },
  ERROR_HASNT_PLAN: {
    description: 'Erro',
    header: 'RETIRE SUA BIKE',
    backRoute: ROUTES.HUB,
    title: '#Partiu? Compre seu plano',
    defaultMessage:
      'Para retirar sua bike e começar a pedalar, você<br/> precisa comprar um plano. Bora?',
    defaultRoute: ROUTES.SELECAO_PAGAMENTO,
    image: pedalada,
    buttonText: 'Comprar Plano',
  },
  SUCCESS_SIGNATURE: {
    description: 'Sucesso',
    title: 'Uhu! Assinatura feita com sucesso!',
    defaultMessage:
      'Agora você já está pronto para desbloquear sua bike e pedalar por aí. <br/> Aproveite!',
    defaultRoute: ROUTES.HUB,
    image: cartaoAprovado,
    buttonText: 'Começar a pedalar',
  },
  ALREADY_HAS_PLAN: {
    description: 'Sucesso',
    title: 'Você já possui um plano ativo.',
    defaultMessage: 'Você já está pronto para desbloquear sua bike e pedalar por ai.',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: pedalada,
    buttonText: 'Começar a pedalar',
  },
  ERROR_HELPCENTER: {
    description: 'Erro',
    title: 'Algo deu errado',
    defaultMessage: 'Não foi possível se conectar a nossa central, tente mais tarde.',
    defaultRoute: ROUTES.HUB,
    image: erro,
    buttonText: 'Voltar',
  },
  ERROR_PLAN_NOT_FOUND: {
    description: 'Erro',
    title: 'Algo deu errado',
    defaultMessage: 'Não foi possível encontrar o plano.',
    defaultRoute: ROUTES.HUB,
    image: erro,
    buttonText: 'Voltar',
  },
  SUCCESS_CREDITCARD_UPDATE: {
    description: 'Sucesso',
    title: 'Uhu! Seu cartão foi atualizado com sucesso!',
    defaultMessage: '',
    defaultRoute: ROUTES.HUB,
    image: cartaoAprovado,
    buttonText: 'Começar a pedalar',
  },
  ERROR_SIGNEDUP: {
    description: 'Erro',
    title: 'Algo deu errado',
    defaultMessage: 'Não foi possível realizar cadastro, entre em contato com o suporte.',
    externalRoute: LINKS.SUPPORT,
    image: opsSomethingWrong,
    buttonText: 'Falar com suporte Tembici',
  },
  ERROR_INVALID_COUPON: {
    description: 'Erro',
    title: 'Não foi possível concluir a compra',
    defaultMessage:
      'O voucher informado não é válido.<br />Por favor, entre em contato com o suporte <br /> para concluir a compra.',
    externalRoute: LINKS.SUPPORT,
    image: erro,
    buttonText: 'Falar com suporte',
  },
  15: {
    header: 'RETIRE SUA BIKE',
    backRoute: ROUTES.HUB,
    description: 'Sucesso',
    title: '#Partiu?',
    defaultMessage:
      'Para desbloquear uma bike e começar a pedalar baixe o app Bike Itaú no Google Play.<br/><br/>Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    externalRoute: URLS.BIKE_ITAU_APP,
    image: pedalada,
    buttonPlaceholder: googlePlay,
  },
  ERROR_BOOTSTRAP: {
    description: 'Erro',
    title: 'Opa! Algo deu errado',
    defaultMessage:
      'A resposta do nosso servidor está<br/>um pouco lenta. Aguarde alguns minutos e<br/>tente novamente.',
    externalRoute: URLS.EXIT_WEBVIEW,
    image: erro,
    buttonText: 'Voltar',
  },
  SUCCESS_MIGRATE_PLAN: {
    description: 'Sucesso',
    title: 'Tudo certo com seu pagamento!',
    defaultMessage:
      'Seu novo plano já está valendo.<br/>Agora, basta desbloquear uma bike<br/> para começar a pedalar 🚲',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: cartaoAprovado,
    buttonText: 'Desbloquear bike',
  },
  HAS_TO_MIGRATE_PLAN: {
    description: 'Sucesso',
    title: 'Parece que você já tem um plano ativo',
    defaultMessage: `Para pedalar com o iFood Pedal, você deve trocar seu plano atual por um dos nossos. <br/><br/>Que tal dar uma olhadinha nas opções?`,
    defaultRoute: ROUTES.MIGRAR_PLANO_SOBRE,
    secondaryRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Vamos lá!',
    buttonSecondaryText: 'Agora Não',
  },
  LAST_RECOVERY_PSWD_TRY: {
    description: 'Erro',
    title: 'Resta apenas uma tentativa de recuperação',
    defaultMessage: `Se o limite de tentativas de senha for<br/>atingido, você só poderá solicitar um novo código no dia seguinte.`,
    defaultRoute: ROUTES.GERAR_TOKEN_LOGIN,
    secondaryRoute: ROUTES.LOGIN,
    image: lastAttempt,
    buttonText: 'Tentar Novamente',
    buttonSecondaryText: 'Agora Não',
  },
  LAST_CHANGE_PSWD_TRY: {
    description: 'Erro',
    title: 'Resta apenas uma tentativa de recuperação',
    defaultMessage: `Se o limite de tentativas de senha for<br/>atingido, você só poderá solicitar um novo código no dia seguinte.`,
    defaultRoute: ROUTES.GERAR_TOKEN_CONTA,
    secondaryRoute: ROUTES.CONTA_CLIENTE,
    image: lastAttempt,
    buttonText: 'Tentar Novamente',
    buttonSecondaryText: 'Agora Não',
  },
  ATTEMPTS_EXPIRED: {
    description: 'Erro',
    title: 'Você atingiu o limite de tentativas de recuperação',
    defaultMessage: `Mas não se preocupe. No próximo dia, você poderá solicitar um novo código de segurança para recuperar sua senha. <br/><br/><strong>Dica:</strong> verifique se seu número cadastrado no iFood Entregadores está atualizado.`,
    defaultRoute: ROUTES.LOGIN,
    image: waitTime,
    buttonText: 'Ok, entendi',
  },
  ATTEMPTS_EXPIRED_CHANGE_PSWD_ACCOUNT: {
    description: 'Erro',
    title: 'Você atingiu o limite de tentativas de recuperação',
    defaultMessage: `Mas não se preocupe. No próximo dia, você poderá solicitar um novo código de segurança para recuperar sua senha. <br/><br/><strong>Dica:</strong> verifique se seu número cadastrado no iFood Entregadores está atualizado.`,
    defaultRoute: ROUTES.CONTA_CLIENTE,
    image: waitTime,
    buttonText: 'Ok, entendi',
  },
  SUCCESS_PASWD_CHANGE: {
    description: 'Sucesso',
    title: 'Sua senha foi alterada!',
    defaultMessage: `Agora você já pode acessar<br/>sua conta e curtir o iFood Pedal 😊`,
    defaultRoute: ROUTES.HUB,
    image: pedalada,
    buttonText: 'Começar a pedalar',
  },
  SUSPENDED_ERROR_QRCODE: {
    description: 'Erro',
    title: 'Desbloqueio indisponível',
    defaultMessage: `Seu plano está suspenso. Entenda mais sobre a situação falando com o suporte.`,
    externalRoute: LINKS.SUPPORT,
    secondaryRoute: ROUTES.HUB,
    image: qrCodeFail,
    buttonText: 'Falar com suporte',
    buttonSecondaryText: 'Voltar',
    primaryEvent: 'UNLOCK_BIKE_SUPORT_CLICK',
  },
  NO_PLAN_ERROR_QRCODE: {
    description: 'Erro',
    title: 'Desbloqueio indisponível',
    defaultMessage: `Para sair pedalando vamos escolher um plano com a sua cara!`,
    defaultRoute: ROUTES.SELECAO_PAGAMENTO,
    secondaryRoute: ROUTES.HUB,
    image: qrCodeFail,
    buttonText: 'Comprar Plano',
    buttonSecondaryText: 'Voltar',
  },
  SIGNUP_ACTIVATION: {
    description: 'Sucesso',
    title: 'Fica, falta pouco!',
    defaultMessage: `Em poucos minutos você conclui
    seu cadastro e já pode começar a pedalar por aí.`,
    defaultRoute: ROUTES.SELECAO_PAGAMENTO,
    externalSecondaryRoute: URLS.EXIT_WEBVIEW,
    secondaryRoute: ROUTES.HUB,
    image: loadingBike,
    buttonText: 'Continuar',
    buttonSecondaryText: 'Voltar',
  },
  SUCCESS_ADDRESS: {
    description: 'Sucesso',
    title: 'Seu endereço foi cadastrado',
    defaultMessage: `Agora você já pode começar a pedalar por aí com o iFood Pedal 😊`,
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    viewEvent: 'UPDATE_ADDRESS_SUCCESS_VIEW',
    image: pedalada,
    buttonText: 'Desbloquear bike',
  },
  PLAN_PURCHASE_PENDING_ADDRESS: {
    description: 'Sucesso',
    title: 'Pagamento realizado,<br/>agora falta pouco!',
    defaultMessage: `Seu plano já está valendo.<br/><br/>Para retirar uma bike, o próximo passo é cadastrar seu endereço. Vamos lá?`,
    primaryEvent: 'UPDATE_ADDRESS_CLICK',
    viewEvent: 'UPDATE_ADDRESS_INFO_VIEW',
    defaultRoute: ROUTES.ENDERECO,
    secondaryRoute: ROUTES.HUB,
    image: pendingAddress,
    buttonText: 'Cadastrar endereço',
    buttonSecondaryText: 'Agora não',
  },
  UNLOCK_BIKE_PENDING_ADDRESS: {
    description: 'Sucesso',
    title: 'Agora falta pouco!',
    defaultMessage: `Seu plano já está valendo.<br/><br/>Para retirar uma bike, o próximo passo é cadastrar seu endereço. Vamos lá?`,
    primaryEvent: 'UPDATE_ADDRESS_CLICK',
    viewEvent: 'UPDATE_ADDRESS_INFO_VIEW',
    defaultRoute: ROUTES.ENDERECO,
    secondaryRoute: ROUTES.HUB,
    image: pendingAddress,
    buttonText: 'Cadastrar endereço',
    buttonSecondaryText: 'Agora não',
  },
  UNLOCK_BIKE_DAILY_TRIPS_EXCEEDED: {
    description: 'Sucesso',
    title: 'Opa! Você atingiu seu limite diário de viagens',
    defaultMessage: `A retirada de bikes elétricas estará disponível novamente amanhã.<br /><br />Quer continuar pedalando? Encontre a estação mais próxima no app bike Itaú e desbloqueie uma bike normal.`,
    defaultRoute: ROUTES.HUB,
    image: dailyExceeded,
    buttonText: 'Ok, entendi',
    viewEvent: 'UNLOCK_BIKE_NO_LIMIT_VIEW',
  },
  UNLOCK_BIKE_DAILY_TRIPS_EXCEEDED_OTHERS: {
    description: 'Sucesso',
    title: 'Opa! Você atingiu seu limite diário de viagens',
    defaultMessage: `A retirada de bikes elétricas estará disponível novamente amanhã.<br /><br />Quer continuar pedalando? Encontre a estação mais próxima no app Tembici e desbloqueie uma bici normal.`,
    defaultRoute: ROUTES.HUB,
    image: dailyExceeded,
    buttonText: 'Ok, entendi',
  },
  LOCK_BIKE_SUCCESS: {
    description: 'Sucesso',
    title: 'Bike entregue!',
    defaultMessage: `Muito obrigado pela devolução!`,
    defaultRoute: ROUTES.HUB,
    secondaryRoute: ROUTES.HUB,
    image: bike,
    buttonText: 'Voltar para o início',
    buttonSecondaryText: 'Bike com problemas?',
  },
  SUCCCESS_UNLOCK_BIKE_V2: {
    description: 'Sucesso',
    title: 'Bike desbloqueada 😄',
    defaultMessage: `Você já pode sair pedalando por aí.<br />Boas entregas!`,
    defaultRoute: ROUTES.HUB,
    image: pedalada,
    buttonText: 'Beleza!',
  },
  QR_CODE_NOT_FOUND: {
    description: 'Erro',
    title: 'Não encontramos o registro dessa bike por aqui...',
    defaultMessage: `Procure outra bike disponível e tente novamente ou procure algum operador no ponto de apoio.`,
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: pedalada,
    buttonText: 'Tentar novamente',
  },
  UNLOCK_BIKE_ERROR: {
    description: 'Erro',
    title: 'Algo deu errado',
    defaultMessage: 'Não foi possível realizar esta ação.<br/>Por favor, tente novamente',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: erro,
    buttonText: 'Tentar novamente',
  },
  LOCK_BIKE_ERROR_SUPPORT_BUFFER_RANGE: {
    description: 'Erro',
    title: 'Você está no ponto de apoio?',
    defaultMessage:
      'Para realizar a devolução da bike é necessário estar no ponto de apoio onde a bike foi retirada.<br/><br/>A bike possui um gps que indica quando ela está no ponto de apoio, assim liberando para a devolução.<br/><br/>Se você estiver no ponto de apoio, e ainda assim, não conseguir devolver a bike, fale com um operador.',
    defaultRoute: ROUTES.HUB,
    image: erro,
    buttonText: 'Tentar novamente',
  },
  UNLOCK_BIKE_ERROR_SUPPORT_BUFFER_RANGE: {
    description: 'Erro',
    title: 'A bike está longe do ponto de apoio',
    defaultMessage: 'Retorne para o ponto de apoio para desbloquear uma bicicleta.',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: erro,
    buttonText: 'Entendi',
  },
  BIKE_ALREADY_IN_TRIP: {
    description: 'Erro',
    title: 'Essa bike já está com outra pessoa',
    defaultMessage: 'Por favor, procure uma outra bike para liberar e tente novamente',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: erro,
    buttonText: 'Liberar outra bike',
  },
  USER_ALREADY_IN_TRIP: {
    description: 'Erro',
    title: 'Você está com uma viagem em aberto',
    defaultMessage: 'Você precisa encerrar essa viagem para liberar outra bike',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: onRoute,
    buttonText: 'Tentar novamente',
  },
  BIKE_NOT_FROM_SUPPORT_BUFFER: {
    description: 'Erro',
    title: 'Não é possível liberar a bike por aqui',
    defaultMessage: 'Você só pode liberar as bikes do ponto de apoio do Ifood Pedal',
    defaultRoute: ROUTES.RETIRADA_DEVOLUCAO,
    image: erro,
    buttonText: 'Entendi',
  },
  USER_HAS_A_PENALTY_START_TRIP: {
    description: 'Erro',
    title: '#Partiu?',
    defaultMessage:
      'O seu plano atual não dá acesso aos benefícios do Ponto de Apoio.<br /><br />Para desbloquear uma bike e começar a pedalar baixe o app Bike Itaú no Google Play.<br /><br />Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Entendi',
    backRoute: ROUTES.HUB,
    header: 'Iniciar Viagem',
  },
  USER_HAS_A_PENALTY_START_TRIP_APP_TEMBICI: {
    description: 'Erro',
    title: '#Partiu?',
    defaultMessage:
      'O seu plano atual não dá acesso aos benefícios do Ponto de Apoio.<br /><br />Para desbloquear uma bike e começar a pedalar baixe o app Tembici no Google Play.<br /><br />Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Entendi',
    backRoute: ROUTES.HUB,
    header: 'Iniciar Viagem',
  },
  USER_HAS_A_PENALTY_START_TRIP_RIO: {
    description: 'Erro',
    title: '#Partiu?',
    defaultMessage:
      'Para desbloquear uma bike e começar a pedalar baixe o app Bike Itaú no Google Play.<br /><br />Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Entendi',
    backRoute: ROUTES.HUB,
    header: 'Iniciar Viagem',
  },
  USER_HAS_A_PENALTY_START_TRIP_NO_HEADER: {
    description: 'Erro',
    title: '#Partiu?',
    defaultMessage:
      'O seu plano atual não dá acesso aos benefícios do Ponto de Apoio.<br /><br />Para desbloquear uma bike e começar a pedalar baixe o app Bike Itaú no Google Play.<br /><br />Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Entendi',
  },
  USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_APP_TEMBICI: {
    description: 'Erro',
    title: '#Partiu?',
    defaultMessage:
      'O seu plano atual não dá acesso aos benefícios do Ponto de Apoio.<br /><br />Para desbloquear uma bike e começar a pedalar baixe o app Tembici no Google Play.<br /><br />Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Entendi',
  },
  USER_HAS_A_PENALTY_START_TRIP_NO_HEADER_RIO: {
    description: 'Erro',
    title: '#Partiu?',
    defaultMessage:
      'Para desbloquear uma bike e começar a pedalar baixe o app Bike Itaú no Google Play.<br /><br />Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    defaultRoute: ROUTES.HUB,
    image: opsSomethingWrong,
    buttonText: 'Entendi',
  },
  USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED: {
    description: 'Erro',
    title: 'Acesso negado',
    defaultMessage:
      'Infelizmente, você foi bloqueado e não tem mais acesso ao benefício do iFood Pedal, pois infringiu os termos de uso da parceria.<br /><br />Em caso de dúvidas, entre em contato com o atendimento do iFood.',
    defaultRoute: ROUTES.HUB,
    image: erro,
    buttonText: 'Entendi',
  },
  CANCEL_AUTO_MIGRATION_SUCCESS: {
    description: 'Sucesso',
    title: 'Cartão de crédito cancelado com sucesso!',
    defaultMessage: 'Para realizar uma nova compra será necessário cadastrar um cartão de crédito',
    defaultRoute: ROUTES.HUB,
    image: cardMachine,
    buttonText: 'Voltar',
  },
  DOWNLOAD_APP_TEMBICI: {
    header: 'RETIRE SUA BIKE',
    backRoute: ROUTES.HUB,
    description: 'Sucesso',
    title: '#Partiu?',
    defaultMessage:
      'Para desbloquear uma bike e começar a pedalar baixe o app Tembici no Google Play.<br/><br/>Entre na sua conta com o mesmo e-mail e senha usados no iFood Pedal e boa pedalada!',
    externalRoute: URLS.TEMBICI_APP,
    image: pedalada,
    buttonPlaceholder: googlePlay,
  },
  BEFORE_REGISTER: {
    titleTop: 'Antes da gente começar',
    defaultMessage:
      "Aqui estão algumas informações importantes pra você, antes de começar:<br><br><strong style='font-size: 20px'>1. Confirme seus dados</strong><br>Revise seus dados já cadastrados e, se necessário, sinalize o que precisa ser corrigido.<br><br><strong style='font-size: 20px'>2. Confirme seu telefone </strong><br>Mantenha seu telefone celular atualizado para receber informações e atualizações.<br><br><strong style='font-size: 20px'>3. Comprove seu endereço</strong><br>Envie um comprovante de endereço em seu nome. Pode ser uma conta de água, luz ou telefone.<br><br><strong style='font-size: 20px'>4. Fotografe seus documentos</strong><br>Tenha seus documentos pessoais em mãos para realizar o seu cadastro.",
    defaultMessageAlign: 'left',
    defaultRoute: ROUTES.SIGNUP_PERSONAL,
    image: driver,
    buttonText: 'Iniciar cadastro',
    header: 'Cadastro',
    buttonVariant: 'contained',
    backRoute: ROUTES.BPV_HOME,
    buttonSecondaryText: 'Voltar mais tarde',
    secondaryRoute: ROUTES.BPV_HOME,
    viewEvent: 'SIGNUP_ONBOARDING_SCREEN_VIEW',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/onboarding`,
    primaryEvent: 'SIGNUP_START_CLICK',
    secondaryEvent: 'SIGNUP_LATER',
    description: '',
    title: '',
  },
  DOCUMENT_FRONT: {
    header: 'Cadastro',
    titleTop: 'Documento de identificação (Frente)',
    image: cardDenied,
    defaultMessageAlign: 'left',
    defaultMessage:
      "<p style='margin-bottom: 4px'>Você pode enviar como documento de identificação a foto do RG ou da CNH.</p><br/><p style='margin-bottom: 4px'>Verifique se todos os campos podem ser lidos e nenhuma parte está cortada.</p><br/><p style='margin-bottom: 4px'>Agora, envie apenas a foto da frente do documento.</p>",
    defaultRoute: isMobile ? ROUTES.SIGNUP_DOCUMENT_FRONT : '',
    buttonText: isMobile ? 'Tirar foto' : '',
    buttonSecondaryText: 'Enviar arquivo',
    hasSwipeable: true,
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/id-validation-front`,
    viewEvent: 'ID_VALIDATION_FRONT_SCREEN_VIEW',
    primaryEvent: 'ID_VALIDATION_FRONT_PHOTO_CLICK',
    swipeableOpenEventName: 'id_validation_front_file_click',
    description: '',
    title: '',
  },
  DOCUMENT_BACK: {
    header: 'Cadastro',
    titleTop: 'Documento de identificação (Verso)',
    image: cardDenied,
    defaultMessageAlign: 'left',
    defaultMessage:
      "<p style='margin-bottom: 4px'>Você pode enviar como documento de identificação a foto do RG ou da CNH.</p><br/><p style='margin-bottom: 4px'>Verifique se todos os campos podem ser lidos e nenhuma parte está cortada.</p><br/><p style='margin-bottom: 4px'>Agora, envie apenas a foto da frente do documento.</p>",
    defaultRoute: isMobile ? ROUTES.SIGNUP_DOCUMENT_BACK : '',
    buttonText: isMobile ? 'Tirar foto' : '',
    buttonSecondaryText: 'Enviar arquivo',
    hasSwipeable: true,
    viewEvent: 'ID_VALIDATION_BACK_SCREEN_VIEW',
    primaryEvent: 'ID_VALIDATION_BACK_PHOTO_CLICK',
    swipeableOpenEventName: 'id_validation_back_file_click',
    description: '',
    title: '',
  },
  BIKE_CARE: {
    title: 'Informações importantes',
    defaultMessage:
      '<br />Não tente remover a bateria ou qualquer peça embutida na bike.<br /><br />Enquanto não estiver pedalando, guarde a bike em local seguro.<br /><br />Carregue a bateria em local seguro, carga máxima de 4 horas.<br /><br />O carregador da bateria é bivolt e funciona como um carregador de celular.',
    image: pedalada,
    header: 'Cuidados da bike',
    backRoute: ROUTES.BPV_HOME,
    defaultMessageAlign: 'left',
    buttonText: 'Entendi',
    defaultRoute: ROUTES.BPV_HOME,
    description: '',
  },
  PROOF_OF_RESIDENCE: {
    header: 'Cadastro',
    titleTop: 'Comprovante de endereço',
    image: proof,
    defaultMessageAlign: 'left',
    defaultMessage:
      "<p style='margin-bottom: 4px'>Veja se todos os campos podem ser lidos e nenhuma parte está cortada.</p><br/><p style='margin-bottom: 4px'>Caso não tenha comprovante de endereço em seu nome, você pode ir ao cartório e providenciar um.</p><br/>",
    defaultRoute: isMobile ? ROUTES.SIGNUP_CAMERA_PROOF_ADDRESS : '',
    buttonText: isMobile ? 'Tirar foto' : '',
    buttonSecondaryText: 'Enviar arquivo',
    hasSwipeable: true,
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/address-validation`,
    viewEvent: 'ADDRESS_VALIDATION_SCREEN_VIEW',
    primaryEvent: 'ID_VALIDATION_FRONT_PHOTO_CLICK',
    swipeableOpenEventName: 'address_validation_file_click',
    description: '',
    title: '',
  },
  SEND_SUCCESS_DOCUMENT: {
    title: 'Sucesso no envio',
    defaultMessage:
      'Sua documentação será analisada, nós vamos te enviar um email, em até 3 dias, com o resultado.',
    image: sendSuccess,
    header: 'Cadastro',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/document-success"`,
    primaryEvent: 'DOCUMENT_SIGNUP_SUCCESS_SCREEN_VIEW',
    description: '',
  },
  ERROR_SERVER_DOCUMENT: {
    title: 'Erro ao enviar a foto',
    defaultMessage:
      'Opa, aconteceu algum problema no envio da sua foto. Tente novamente por favor.',
    defaultRoute: ROUTES.SIGNUP_DOCUMENT,
    image: uploadError,
    buttonText: 'Tentar novamente',
    header: 'Algo deu errado',
    viewEvent: 'DOCUMENT_SIGNUP_FAIL_SCREEN_VIEW',
    primaryEvent: 'DOCUMENT_PHOTO_FAIL_CLICK',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/address-photo-fail`,
    description: '',
  },
  ERROR_SERVER_TOKEN: {
    title: 'Esse link expirou ou já foi utilizado',
    defaultMessage:
      'Se o seu link expirou, acesse o Bike pra você e vamos ajudar você com um novo link.',
    image: messageNotSend,
    header: 'Link Expirado',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/email-confirmation-link-expired`,
    viewEvent: 'EMAIL_LINK_EXPIRED_SCREEN_VIEW',
    primaryEvent: 'EMAIL_LINK_EXPIRED_PRIMARY_BUTTON',
    description: '',
  },
  SIGNUP_SERVER: {
    title: 'Algo deu errado',
    defaultMessage: 'Não foi possível realizar esta o cadastro.<br/>Por favor, tente novamente',
    defaultRoute: ROUTES.SIGNUP_PERSONAL,
    image: erro,
    buttonText: 'Tentar novamente',
    header: 'Algo deu errado',

    viewEvent: 'ERROR',
    pageTitle: 'error_signup',
    primaryEvent: 'ERROR_PRIMARY_BUTTON',
    description: '',
  },
  SIGNUP_CHECK_YOUR_EMAIL: {
    title: 'Acesse seu email',
    defaultMessage:
      "Enviamos um link pra você enviar os documentos, confira algumas dicas importantes:<br><br><span class='bulletDot'></span>Lembre de verificar se o email está na caixa de spam ou lixo.<br><br><span class='bulletDot'></span>Você vai precisar de um documento de identidade.<br><br><span class='bulletDot'></span>O comprovante de endereço deve estar no seu nome ou reconhecido em cartório",
    defaultRoute: ROUTES.BPV_HOME,
    buttonText: 'Entendi',
    image: driverPose,
    header: 'Cadastro',
    secondaryRoute: ROUTES.SIGNUP_EMAIL_RESEND,
    buttonSecondaryText: 'Não recebi o email',
    defaultMessageAlign: 'left',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/email-confirm`,
    primaryEvent: 'EMAIL_CONFIRM_OK_CLICK',
    secondaryEvent: 'EMAIL_NOT_RECEIVED_CLICK',
    viewEvent: 'EMAIL_CONFIRM_SCREEN_VIEW',
    description: '',
  },
  SIGNUP_CHECK_YOUR_EMAIL_RESEND: {
    title: 'Acesse seu email',
    defaultMessage:
      "Enviamos um link pra você enviar os documentos, confira algumas dicas importantes:<br><br><span class='bulletDot'></span>Lembre de verificar se o email está na caixa de spam ou lixo.<br><br><span class='bulletDot'></span>Você vai precisar de um documento de identidade.<br><br><span class='bulletDot'></span>O comprovante de endereço deve estar no seu nome ou reconhecido em cartório",
    defaultRoute: ROUTES.BPV_HOME,
    buttonText: 'Entendi',
    image: driverPose,
    header: 'Cadastro',
    secondaryRoute: ROUTES.SIGNUP_EMAIL_RESEND,
    buttonSecondaryText: 'Não recebi o email',
    defaultMessageAlign: 'left',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/email-confirm`,
    primaryEvent: 'EMAIL_CONFIRM_OK_CLICK',
    secondaryEvent: 'EMAIL_NOT_RECEIVED_CLICK',
    viewEvent: 'EMAIL_CONFIRM_SCREEN_VIEW',
    description: '',
  },
  SMS_EXCEED_RETRIES: {
    title: 'Você atingiu o limite de tentativas de envio',
    defaultMessage:
      'Não se preocupe, amanhã, você poderá solicitar um novo código para verificar o seu número.<br><br><strong>Dica</strong>: Verifique se seu número cadastrado está correto.',
    defaultRoute: ROUTES.BPV_HOME,
    image: waitTime,
    buttonText: 'Iniciar cadastro',
    header: 'Limite de envio',
    buttonVariant: 'contained',
    backRoute: ROUTES.BPV_HOME,
    viewEvent: 'PHONE_RESEND_LIMIT_EXCEEDED_SCREEN_VIEW',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/phone-validation/resend_limit_exceeded`,
    primaryEvent: 'PHONE_RESEND_LIMIT_EXCEEDED_OK_CLICK',
    secondaryEvent: 'PHONE_RESEND_LIMIT_EXCEEDED_HELP_CLICK',
    description: '',
  },
  ERROR_SERVER_SMS: {
    title: 'Algo deu errado',
    defaultMessage: 'Não foi possível realizar esta ação.<br/>Por favor, tente novamente',
    defaultRoute: ROUTES.SIGNUP_PHONE,
    image: erro,
    buttonText: 'Tentar novamente',
    header: 'Algo deu errado',
    viewEvent: 'ERROR',
    pageTitle: 'error_sms',
    primaryEvent: 'ERROR_PRIMARY_BUTTON',
    description: '',
  },
};
