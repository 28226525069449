import styled from 'styled-components';

interface ISplashImageProps {
  minHeight?: string;
}

export const SplashImage = styled.img`
  min-height: ${({ minHeight }: ISplashImageProps): string => minHeight ?? '20px'};
  width: 180px;
  margin: 0 auto;
  margin-bottom: 36px;
  display: block;
`;
