import styled from 'styled-components';
import { InputAdornment } from '@mui/material';
import { Field as CustomField } from '../../molecules';
import { errorColor, primaryColor, greyColor, fontColor } from '../../../themes/_colors';
import { FieldComponent } from '../../molecules/Field';

export const Field = styled(CustomField)`
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid ${primaryColor};
  }

  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root {
    color: ${primaryColor};
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${errorColor};
  }
  .MuiInputAdornment-root {
    display: none;
  }
` as FieldComponent; // typescript conflicts styled-component and Field control props

export const CreditCardField = styled(CustomField)`
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: 2px solid ${greyColor(50)};
  }

  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root,
  .MuiInputBase-root.Mui-disabled {
    background-color: ${greyColor(100)};
    color: ${fontColor};
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }
`;

export const Adornement = styled(InputAdornment)`
  .MuiTypography-colorTextSecondary {
    color: ${primaryColor};
    font-weight: 600;
  }
`;
