import styled from 'styled-components';
import MaterialButton from '@mui/material/Button';
import MaterialCircularProgress from '@mui/material/CircularProgress';
import { ITheme } from '../../../interfaces/theme.interface';
import { disabledColor, greyColor } from '../../../themes/_colors';

export const Button = styled(MaterialButton)`
  font-weight: 700;
  height: 50px;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-transform: none;

  &:disabled {
    background-color: ${disabledColor};
    color: ${greyColor(400)};
  }
`;

export const CircularProgress = styled(MaterialCircularProgress)`
  color: ${({ theme }: ITheme): string => theme.palette.primary.main};
`;
