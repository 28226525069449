import { useContext } from 'react';
import { PLANS } from '../API/tempartner';
import { EVENTS } from '../constants/events.const';
import { PlanPurchaseContext } from '../contexts/PlanPurchaseContext';
import { IAccess } from '../interfaces/access.interface';
import { IError } from '../interfaces/error.interface';
import { UseBuildCustomMutation } from '../interfaces/query.interface';
import { errorService } from '../services/error.service';
import Events from '../services/events.service';
import { postData } from '../services/request.service';
import { formatUrl } from '../utils/format';
import { useGuardedMutation } from './useGuardedMutation';

export interface IPurchasePlanParams {
  plan_id: string;
  credit_card?: string;
  voucher?: string;
  payment_type: string;
  auto_renew: boolean;
  region: string;
}

const eventActions = {
  external: {
    success: 'PLAN_PURCHASE_PAYMENT_EXTERNAL_SUCCESS',
    fail: 'PLAN_PURCHASE_PAYMENT_EXTERNAL_FAIL',
  },
  creditCard: {
    success: 'PLAN_PURCHASE_CREDIT_CARD_SUCCESS',
    fail: 'PLAN_PURCHASE_CREDIT_CARD_FAIL',
  },
} as const;

export const usePurchasePlan: UseBuildCustomMutation<IAccess, IPurchasePlanParams> = (
  options = {},
) => {
  const { isExternalPayment } = useContext(PlanPurchaseContext);
  return useGuardedMutation(async purchase => {
    const eventAction = eventActions[isExternalPayment() ? 'external' : 'creditCard'];

    const { plan_id, ...body } = purchase;

    const url = formatUrl(PLANS.BUY_PLAN, [plan_id]);
    try {
      const response = await postData<IAccess>(url, body);
      const category = EVENTS.CATEGORIES.PLAN_PURCHASE;
      const label = EVENTS.LABELS.SUCCESS;

      const baseEvent = { category, label };

      Events.logEvent({ ...baseEvent, action: EVENTS.ACTIONS[eventAction.success] });
      Events.logEvent({ ...baseEvent, action: EVENTS.ACTIONS.PLAN_PURCHASE_SUCCESS });

      return response;
    } catch (err) {
      const errorMessage = JSON.stringify(err);
      const category = `message: ${errorMessage}`;
      const label = EVENTS.LABELS.FAIL;

      const baseEvent = { category, label };
      const eventData = { message: errorMessage };

      Events.logEvent({ ...baseEvent, action: EVENTS.ACTIONS[eventAction.fail] }, eventData);
      Events.logEvent({ ...baseEvent, action: EVENTS.ACTIONS.PLAN_PURCHASE_FAIL }, eventData);

      throw errorService(err as IError);
    }
  }, options);
};
