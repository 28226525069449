import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';
import { primaryColor, whiteColor, greyColor } from '../../../themes/_colors';

export const NavBar = styled.nav`
  height: 60px;
  background: ${whiteColor};
  padding: 0 16px 0 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  margin-top: 4px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.65px;
`;

export const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

interface IStepsProps extends ITheme {
  current: number;
  total: number;
}

export const Steps = styled.div`
  position: relative;
  height: 4px;
  width: 100%;
  background-color: ${greyColor(300)};

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    z-index: 1;
    background-color: ${primaryColor};
    ${({ current, total }: IStepsProps): CssStyles =>
      css`
        width: calc((${current} / ${total}) * 100%);
      `}
  }
`;
