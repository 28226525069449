import { Box, Divider, List, ListItem, Typography } from '@mui/material';

import { IPlan } from '../../../interfaces/plan.interface';
import { breakLines, breakMoney } from '../../../utils/format';
import * as S from './style';

interface IPlanDisplayProps {
  plan: IPlan;
  checked: boolean;
  onClick: () => void;
}

const PlanDisplay: React.FC<IPlanDisplayProps> = ({ plan, checked, onClick }) => {
  const {
    name,
    value: { symbol, formated_price },
    description,
  } = plan;
  const [faceValue, cents] = breakMoney(formated_price);

  const descriptions = breakLines(description);

  return (
    <S.CustomCard data-testid={`plan-display-${plan.id}`} onClick={onClick} $checked={checked}>
      <Box
        marginTop={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <S.CustomRadio checkedIcon={<S.RadioChecked />} checked={checked} />
        <Typography fontWeight={800} textAlign="center" variant="h1" paddingY="18px">
          {name}
        </Typography>
      </Box>
      <Divider />
      <S.Section>
        <Box display="flex" justifyContent="center">
          <Typography fontWeight={800} alignSelf="flex-top" marginRight="6px">
            {symbol}
          </Typography>
          <Typography fontWeight={800} variant="h1">
            {faceValue},
          </Typography>
          <Typography fontWeight={800} alignSelf="flex-end" marginBottom="4px" marginLeft="2px">
            {cents}
          </Typography>
        </Box>
      </S.Section>
      <Divider />
      <Box paddingX={1}>
        <List>
          {descriptions.map((detail, id) => (
            <ListItem alignItems="flex-start" key={id}>
              <S.CheckIcon />
              <span dangerouslySetInnerHTML={{ __html: detail }} />
            </ListItem>
          ))}
        </List>
      </Box>
    </S.CustomCard>
  );
};

export default PlanDisplay;
