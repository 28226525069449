import { Box, Snackbar, SnackbarContent, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { ToastProps } from './types';
import { useToast } from '../../../hooks/useToast';

export const Toast = ({ title, variant, ...props }: ToastProps): JSX.Element => {
  const { showToast, setShowToast } = useToast();
  return (
    <Snackbar autoHideDuration={5000} open={showToast} onClose={setShowToast} {...props}>
      <SnackbarContent
        sx={{
          display: 'flex',
          bgcolor: variant === 'success' ? 'success.light' : 'error.main',
        }}
        message={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            {variant === 'success' ? (
              <>
                <CheckCircleIcon sx={{ color: '#fff', alignSelf: 'center' }} />
              </>
            ) : (
              <>
                <CancelIcon sx={{ color: '#fff', alignSelf: 'center' }} />
              </>
            )}
            <Typography marginLeft="8px" variant="p5">
              {title}
            </Typography>
          </Box>
        }
      />
    </Snackbar>
  );
};
