export const REGIONS = {
  SP: 'bike-sampa',
  RJ: 'bike-rio',
  SSA: 'bike-salvador',
  PE: 'bike-pernambuco',
  POA: 'bike-porto-alegre',
  BSB: 'bike-brasilia',
  CUR: 'bike-curitiba',
} as const;

export type RegionsKeys = keyof typeof REGIONS;
export type Region = typeof REGIONS[RegionsKeys];
