import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';

import { Box, Card, CardContent, TypeBackground } from '@mui/material';

import { ITheme } from '../../../interfaces/theme.interface';
import { errorColor } from '../../../themes/_colors';

interface IProps extends ITheme {
  $alignItems?: string;
  variantstyle: 'primary' | 'wideHigh' | 'wideLow';
}

type WideHighStyleHub = (props: IProps) => CssStyles | false;
type WideLowStyle = (props: IProps) => CssStyles | false;

const wideLowStyle =
  (styles: CssStyles): WideLowStyle =>
  ({ variantstyle }): CssStyles | false =>
    variantstyle === 'wideLow' && styles;

const wideHighCardStyle =
  (styles: CssStyles): WideHighStyleHub =>
  ({ variantstyle }): CssStyles | false =>
    variantstyle === 'wideHigh' && styles;

interface IButtonCardProps extends IProps {
  background: keyof TypeBackground;
  disabled: boolean;
}

export const ButtonCard = styled(Card)`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.24);
  min-width: 140px;
  color: ${({ background, theme }: IButtonCardProps): string =>
    background === 'red' ? theme.palette.common.white : 'initial'};

  background: ${({ background, theme }: IButtonCardProps): string =>
    theme.palette.background[background]};

  ${({ disabled }: IButtonCardProps): CssStyles | false =>
    disabled &&
    css`
      filter: opacity(0.5);
    `}

  width: calc(50% - 8px / 2);
  ${wideLowStyle(
    css`
      width: 100%;
    `,
  )}
  ${wideHighCardStyle(
    css`
      width: 100%;
      min-width: 350px;
    `,
  )}
`;

export const Container = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 112px;
  &:last-child {
    padding-bottom: 16px;
  }
  ${wideLowStyle(css`
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 72px;
    gap: 16px;
  `)}

  ${wideHighCardStyle(css`
    flex-direction: column;
    align-items: center;
    min-height: 112px;
    gap: 16px;
  `)}
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: flex-end;

  ${wideLowStyle(css`
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: 12px;
  `)}

  ${wideHighCardStyle(css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    width: 100%;
  `)}
`;

export const TextBox = styled(Box)`
  ${wideHighCardStyle(css`
    flex: 1;
  `)}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${wideLowStyle(
    css`
      flex-shrink: 0;
      width: 24px;
    `,
  )}
  ${wideHighCardStyle(
    css`
      flex-shrink: 0;
      width: 24px;
      align-self: flex-end;
    `,
  )}
`;

export const Text = styled.div`
  max-width: 80%;
  font-size: 14px;
  ${wideLowStyle(
    css`
      font-weight: 700;
    `,
  )}
`;

export const SubText = styled.div`
  max-width: 80%;
  font-size: 12px;
  color: ${errorColor};
  ${wideLowStyle(
    css`
      font-weight: 700;
    `,
  )}
`;

export const About = styled.div`
  color: hsla(0, 0%, 44%, 1);
  flex-basis: 100%;
  margin-top: auto;
  font-size: 12px;
  white-space: break-spaces;
`;

export const ExternalIcon = styled.img`
  width: 24px;
  height: 24px;
`;
