import { useContext } from 'react';
import { SEND_WPP } from '../API/tempartner';
import { EVENTS } from '../constants/events.const';
import { DriverContext } from '../contexts/DriverContext';
import { UseBuildCustomMutation } from '../interfaces/query.interface';
import { buildPostRequest } from '../utils/request';
import { useGuardedMutation } from './useGuardedMutation';

type UnknownResponse = Record<string, unknown>;

export const useSendCourseLink: UseBuildCustomMutation<UnknownResponse> = (options = {}) => {
  const { region } = useContext(DriverContext);

  const data = {
    region,
  };
  const url = SEND_WPP.POST;

  const success = {
    action: EVENTS.ACTIONS.RESEND_WHATSAPP_LINK_SUCCESS,
    category: EVENTS.CATEGORIES.RESEND_WHATSAPP,
  };

  const error = {
    action: EVENTS.ACTIONS.RESEND_WHATSAPP_LINK_FAIL,
  };

  const events = { success, error };
  const request = buildPostRequest<UnknownResponse>({ url, data }, events);

  return useGuardedMutation<UnknownResponse>(request, options);
};
