/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const isImageReady = (image: string | undefined) => {
  return image && typeof image === 'string' && image.startsWith('data:');
};

export const isFileSizeValid = (file: any, maxSizeMB: number) => {
  const maxSizeBytes = maxSizeMB * 1024 * 1024;

  return file.size <= maxSizeBytes;
};

export const isFileSizeValidBase64 = (dataURL: string, maxSizeMB: number) => {
  const binary = atob(dataURL.split(',')[1]);
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([buffer], { type: 'image/jpeg' });

  const maxSizeBytes = maxSizeMB * 1024 * 1024;

  return blob.size <= maxSizeBytes;
};

export const isFileInRightFormat = (fileType: string): boolean => {
  return (
    fileType === 'image/png' ||
    fileType === 'image/jpeg' ||
    fileType === 'image/jpg' ||
    fileType === 'application/pdf'
  );
};
