import { Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import { LINKS } from '../../../constants/routes.const';
import { MessageTypes, MESSAGE_DATA } from '../../../pages/InfoView/messages.const';
import { openNewUrl } from '../../../utils/route';
import { Button } from '../../atoms';
import * as S from './style';

export enum AlertTestId {
  dialog = 'dialog',
  alertClose = 'alert-close',
}
interface IAlertProps {
  messageType: MessageTypes;
  message?: string | null;
  open: boolean;
  handleClose: () => unknown;
  externalSecondaryRoute?: boolean;
  supportChat?: boolean;
  actionsDirection?: 'row' | 'column';
}

const Alert: React.FC<IAlertProps> = ({
  messageType,
  open,
  message,
  handleClose,
  supportChat = false,
  actionsDirection = 'column',
}) => {
  const {
    title,
    image,
    buttonText,
    buttonSecondaryText,
    defaultMessage,
    secondaryRoute,
    externalSecondaryRoute,
  } = MESSAGE_DATA[messageType];

  if (message === null) return null;

  let secondaryRouteProps;
  let secondaryExternalRouteProps;
  if (secondaryRoute) secondaryRouteProps = { to: secondaryRoute };
  if (externalSecondaryRoute) secondaryExternalRouteProps = openNewUrl(externalSecondaryRoute);

  return (
    <Dialog open={open} onClose={handleClose} data-testid="dialog">
      <S.Content>
        <S.SplashImage src={image} width="180px" />
        <S.Title>{title}</S.Title>
        <S.Message>{message ?? defaultMessage}</S.Message>
      </S.Content>

      <S.Actions direction={actionsDirection}>
        <Button data-testid={AlertTestId.alertClose} onClick={handleClose} color="primary">
          {buttonText}
        </Button>

        {!externalSecondaryRoute && secondaryRouteProps && (
          <Link {...secondaryRouteProps}>
            <Button variant="text" color="primary">
              {buttonSecondaryText}
            </Button>
          </Link>
        )}

        {externalSecondaryRoute && secondaryRouteProps && (
          <a {...secondaryExternalRouteProps}>
            <Button variant="text" color="primary">
              {buttonSecondaryText}
            </Button>
          </a>
        )}

        {supportChat && (
          <a {...openNewUrl(LINKS.SUPPORT)}>
            <Button color="primary" variant="text">
              Falar com suporte Tembici
            </Button>
          </a>
        )}
      </S.Actions>
    </Dialog>
  );
};

export default Alert;
