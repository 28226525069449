import { EVENTS } from '../../constants/events.const';
import Events from '../../services/events.service';

export default {
  pageView: (): void => {
    Events.logEvent({
      action: EVENTS.ACTIONS.WARNING_LIST_PAGE_VIEW,
      category: EVENTS.CATEGORIES.PAGE,
      label: EVENTS.LABELS.SUCCESS,
    });
  },
};
