import { useContext } from 'react';
import { QRCODE } from '../API/tempartner';
import { EVENTS } from '../constants/events.const';
import { QueryKeys } from '../constants/queryKeys.const';
import { DriverContext } from '../contexts/DriverContext';
import { IQRCode, IQRCodeResponse } from '../interfaces/qrCode.interface';
import { UseBuildCustomQuery } from '../interfaces/query.interface';
import { buildPostRequest } from '../utils/request';
import { useGuardedQuery } from './useGuardedQuery';

export const useQrCode: UseBuildCustomQuery<IQRCode> = (options = {}) => {
  const url = QRCODE.GENERATE;
  const { region } = useContext(DriverContext);
  const data = { region };

  const success = {
    action: EVENTS.ACTIONS.CODE_GENERATE_SUCCESS,
    category: EVENTS.CATEGORIES.CODE_GENERATE,
  };

  const error = {
    action: EVENTS.ACTIONS.CODE_GENERATE_FAIL,
  };

  type Select = (params: IQRCodeResponse) => IQRCode;
  const select: Select = ({ qrcode, ...results }) => ({
    ...results,
    qrcode: JSON.stringify(qrcode),
  });

  const events = { success, error };
  const postConfig = { url, data, select };

  const request = buildPostRequest<IQRCodeResponse, IQRCode>(postConfig, events);

  return useGuardedQuery<IQRCode>([QueryKeys.qrCode], () => request(), options);
};
