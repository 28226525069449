export const CONTENT = {
  header: 'Curso Pedal Responsa',
  title:
    'Verifique se o número de telefone está correto e envie o link do curso para o seu Whatsapp.',
  subtitle: 'Se precisar, altere o número de telefone no atendimento do iFood.',
  primaryButton: 'Enviar link pelo whatsapp',
  secondaryButton: 'Entrar em contato com iFood',
  linkSuccessfullySended: 'Link do curso Pedal Responsa enviado',
  linkFailToSend: 'Não foi possível reenviar o link do curso',
};
