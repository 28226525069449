import { Box } from '@mui/material';
import { Cancel, Error, CheckCircle as Check } from '@mui/icons-material';
import * as S from './style';

type Position = 'fixed' | 'static' | 'inherit' | 'relative';
const Icons = {
  cancel: Cancel,
  error: Error,
  check: Check,
} as const;

type IconKeys = keyof typeof Icons;

interface ISnackProps {
  message: string;
  open: boolean;
  onClick: () => void;
  onClose: () => void;
  type?: S.SnackType;
  position?: Position;
  autoHideDuration?: number;
  icon?: IconKeys;
  noIcon?: boolean;
}

const SnackMessage: React.FC<ISnackProps> = ({
  message,
  open,
  onClose,
  onClick,
  type = 'success',
  autoHideDuration = 8000,
  position = 'fixed',
  icon = 'cancel',
  noIcon = false,
}) => {
  const Icon = Icons[icon];

  return (
    <S.SnackMessage
      data-testid="snack-message"
      style={{ position }}
      type={type}
      open={open}
      onClose={onClose}
      onClick={onClick}
      autoHideDuration={autoHideDuration}
    >
      <Box display="flex" borderRadius={4}>
        {!noIcon && <Icon />}
        <Box marginLeft={1} marginTop="5px" fontSize={12}>
          {message}
        </Box>
      </Box>
    </S.SnackMessage>
  );
};

export default SnackMessage;
