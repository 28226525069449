export const unlockBikeSuccess = `<div style="display:flex; flex-direction: column; align-items:flex-start; gap:20px">
        <div style="display: flex; gap: 8px; align-items: center; text-align:start">
            <div style="display:flex; align-items:center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5684_21614)">
                    <path d="M21.25 13.25V16.67C21.25 17.82 20.32 18.75 19.17 18.75H4.83C3.68 18.75 2.75 17.82 2.75 16.67V7.83C2.75 6.68 3.68 5.75 4.83 5.75H19.17C20.32 5.75 21.25 6.68 21.25 7.83V9.25" stroke="#3E3E3E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path d="M3 9.25H21.25" stroke="#3E3E3E" stroke-width="1.5" stroke-miterlimit="10"/>
                    <path d="M5.65002 15.25H8.51002" stroke="#3E3E3E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_5684_21614">
                    <rect width="20" height="14.5" fill="white" transform="translate(2 5)"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
            Tenha um cartão de crédito válido em mãos.
        </div>

        <div style="display: flex; gap: 8px; align-items: center; text-align:start">
            <div style="display:flex; align-items:center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5684_21617)">
                    <g clip-path="url(#clip1_5684_21617)">
                    <path d="M21.22 11.99C21.22 6.89 17.09 2.75 11.98 2.75C6.87 2.75 2.75 6.88 2.75 11.99C2.75 17.1 6.88 21.23 11.99 21.23C14.95 21.23 17.58 19.84 19.27 17.68" stroke="#3E3E3E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    </g>
                    <path d="M15.364 9L9.00005 15.364" stroke="#3E3E3E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 9L15.364 15.364" stroke="#3E3E3E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <defs>
                    <clipPath id="clip0_5684_21617">
                    <rect width="19.97" height="19.97" fill="white" transform="translate(2 2)"/>
                    </clipPath>
                    <clipPath id="clip1_5684_21617">
                    <rect width="19.97" height="19.97" fill="white" transform="translate(2 2)"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
            Se você tiver um plano do iFood pedal, ele será cancelado automaticamente.
        </div>



    </div>`;
