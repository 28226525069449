import { MutationFunction, useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { IErrorNormalized } from '../interfaces/error.interface';
import { isBlockError, isCatastrophicError, isPenaltyError } from '../services/error.service';
import { useGuard } from './useGuard';
import { usePenalty } from './usePenalty';
import { useBlock } from './useBlock';

type GuardedMutation = <TData = unknown, TVariables = void, TContext = unknown>(
  fn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, IErrorNormalized, TVariables, TContext>, 'mutationFn'>,
) => UseMutationResult<TData, IErrorNormalized, TVariables, TContext>;

export const useGuardedMutation: GuardedMutation = (fn, options = {}) => {
  const { onError, onSettled } = options;
  const guard = useGuard();
  const penalty = usePenalty();
  const block = useBlock();

  type Settled = typeof onSettled;
  type Error = typeof onError;

  const guardedError: Error = (error, variables, context) => {
    if (isCatastrophicError(error)) return guard();
    if (isPenaltyError(error)) return penalty();
    if (isBlockError(error)) return block();
    if (onError) onError(error, variables, context);
  };

  const guardedSettled: Settled = (data, error, variables, context) => {
    if (isCatastrophicError(error)) return guard();
    if (isPenaltyError(error)) return penalty();
    if (isBlockError(error)) return block();
    if (onSettled) onSettled(data, error, variables, context);
  };

  const guardedOptions = {
    ...options,
    onSettled: guardedSettled,
    onError: guardedError,
  };

  return useMutation(fn, guardedOptions);
};
