import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Route,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { getEnv } from '../utils/env';

const init = (): void => {
  const dsn = getEnv('REACT_APP_SENTRY_DSN');
  const environment = getEnv('REACT_APP_ENVIRONMENT');
  if (dsn) {
    Sentry.init({
      enabled: true,
      dsn,
      environment,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
};

const setUser = (id: string): void => {
  if (getEnv('REACT_APP_SENTRY_DSN')) {
    Sentry.setUser({
      id,
    });
  }
};

const logMessage = (message: string): void => {
  Sentry.captureMessage(message);
};

export const SentryRoute = Sentry.withSentryReactRouterV6Routing(Route);

const SentryService = {
  init,
  setUser,
  logMessage,
};

export default SentryService;
