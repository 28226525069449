import { ListItemText, ListItem } from '@mui/material';
import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';
import { ITheme } from '../../../interfaces/theme.interface';
import { blackColor, greyColor, primaryColor, whiteColor } from '../../../themes/_colors';
import { spacing } from '../../../themes/_helpers';
import { centerFlex, circle } from '../../../themes/_mixins';

interface IDisabledProps extends ITheme {
  $disabled: boolean;
}

export const Circle = styled.div`
  ${circle(5)}
  ${centerFlex}
  background: ${primaryColor};
  font-weight: 700;
  color: ${whiteColor};
  line-height: ${spacing(1)};
  flex-shrink: 0;
  font-size: ${spacing(2)};

  ${({ $disabled, theme }: IDisabledProps): CssStyles | false =>
    $disabled &&
    css`
      background-color: ${greyColor(100)({ theme })};
    `}
`;

export const ItemText = styled(ListItemText)`
  line-height: ${spacing(1)};
  margin: 0px;
  margin-left: ${spacing(1)};

  .MuiListItemText-secondary {
    color: ${greyColor(600)};
  }

  .MuiListItemText-primary {
    color: ${blackColor};
  }
`;

export const CustomListItem = styled(ListItem)`
  border-bottom: 1px solid ${greyColor(300)};
  padding: ${spacing(3)} 0px;

  ${({ $disabled }: IDisabledProps): CssStyles | false =>
    $disabled &&
    css`
      filter: opacity(0.5);
    `}
`;
