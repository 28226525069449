import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { CssBaseline, ThemeProvider, StyledEngineProvider as StylesProvider } from '@mui/material';
import theme from './themes';
import { DriverContext, useDriver } from './contexts/DriverContext';
import { useVersion, VersionContext } from './contexts/VersionContext';
import { PlanPurchaseContext, usePlanPurchase } from './contexts/PlanPurchaseContext';
import { RemoteConfigContext, useRemoteConfig } from './contexts/RemoteConfigContext';

const Providers: React.FC = ({ children }) => {
  const version = useVersion();
  const driver = useDriver();
  const planPurchase = usePlanPurchase();
  const remoteConfigValues = useRemoteConfig();

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <DriverContext.Provider value={driver}>
            <VersionContext.Provider value={version}>
              <PlanPurchaseContext.Provider value={planPurchase}>
                <RemoteConfigContext.Provider value={remoteConfigValues}>
                  <CssBaseline />
                  {children}
                </RemoteConfigContext.Provider>
              </PlanPurchaseContext.Provider>
            </VersionContext.Provider>
          </DriverContext.Provider>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};
export default Providers;
