import { STORAGE } from '../constants/storage.const';
import {
  IPlan,
  IPLanResponse,
  OtherPlan,
  PlanShapes,
  SignedPlanStatus,
  WithoutPlan,
} from '../interfaces/plan.interface';
import { getItem } from './localStorage';

export const getRegion = (regions: Array<string>): string => {
  const storedRegion = getItem<string>(STORAGE.LOCATION);
  return regions.find(region => region.includes(storedRegion as string)) || '';
};

export const normalizePlan = (plan: IPLanResponse): IPlan => {
  const {
    id,
    description,
    currency,
    start_time,
    end_time,
    price: stringPrice,
    price_label: formated_price,
    short_description,
    title: name,
    location_references,
  } = plan;

  const locationReference = getRegion(location_references);
  const price = +stringPrice;
  const symbol = formated_price.split(/\s?\d/)[0];
  const duration = { start_time, end_time };
  const value = { currency, price, formated_price, symbol };
  return {
    id,
    name,
    description,
    short_description,
    value,
    duration,
    location_reference: locationReference,
  };
};

export const hasSignedPartner = (userPlan: PlanShapes): userPlan is IPlan => {
  return !!Object.keys(userPlan || {}).length;
};

export const shouldMigratePlan = (userPlan: PlanShapes): userPlan is OtherPlan => userPlan === null;

export const withoutPlan = (userPlan: PlanShapes): userPlan is WithoutPlan => {
  return !(hasSignedPartner(userPlan) || shouldMigratePlan(userPlan));
};

/**
 * verify planStatus for given PlanShape
 * @param userPlan implements PlanShapes, which can be null, {}, or IPlan
 * @returns SignedPlanStatus for the given matching shape
 */
export const getPlanStatus = (userPlan: PlanShapes): SignedPlanStatus => {
  if (shouldMigratePlan(userPlan)) return SignedPlanStatus.SIGNED_OTHER;
  return hasSignedPartner(userPlan) ? SignedPlanStatus.SIGNED_PARTNER : SignedPlanStatus.NOT_SIGNED;
};
