import { Box, Divider } from '@mui/material';
import { ValidationStep } from '../../../interfaces/rewards.interface';
import { isBatchCompleted, isStepDone } from '../../../utils/rewards';
import { StepStatus } from '../../molecules';
import * as S from './style';

const RewardStepStatus = (step: ValidationStep): JSX.Element => {
  const { label, rule_type, value, total_required } = step;
  const done = isStepDone(step);

  const renderBaches = (): JSX.Element[] => {
    const batches: JSX.Element[] = [];
    if (!total_required) return batches;

    for (let batch = 1; batch <= total_required; batch++) {
      const isBatchDone = isBatchCompleted(batch, step);
      batches.push(<S.Batch data-testid="batch" $done={isBatchDone} key={batch} />);
    }
    return batches;
  };

  return (
    <Box component="section">
      <StepStatus done={done} label={label} />
      {rule_type === 'batches' && (
        <Box marginBottom={2} paddingX={1}>
          <S.Trips textTransform="uppercase" variant="h5" align="right">
            {value} de {total_required} viagens
          </S.Trips>
          <Box display="flex" style={{ gap: '2px' }}>
            {renderBaches()}
          </Box>
        </Box>
      )}
      <Divider />
    </Box>
  );
};

export default RewardStepStatus;
