import { createContext } from 'react';
import { getItem, storeItem } from '../utils/localStorage';
import { STORAGE } from '../constants/storage.const';
import { useQueryParams } from '../hooks/useQueryParams';
import {
  AppVariant,
  AppVersion,
  APP_VERSION,
  APP_VARIANT,
  AppVersionKeys,
} from '../constants/appVariant.const';
import { PARAMS } from '../constants/params.const';
import SentryService from '../services/sentry.service';

export interface IVersionContext {
  version: AppVersion;
  variants: AppVariant[];
  isVersion: (version: string) => boolean;
  hasVariant: (variant: AppVariant) => boolean;
}

export const VersionInitialValues: IVersionContext = {
  version: APP_VERSION.default,
  variants: [APP_VARIANT.default],
  isVersion: () => false,
  hasVariant: () => false,
};

export const VersionContext = createContext(VersionInitialValues);

const variantBreaker = '-';

export const useVersion = (): IVersionContext => {
  const params = useQueryParams();
  const initialVersion =
    params.get(PARAMS.VERSION) || getItem<AppVersion>(STORAGE.APP_VERSION) || '';
  const version = Object.keys(APP_VERSION).some(
    key => APP_VERSION[key as AppVersionKeys] === initialVersion,
  )
    ? (initialVersion as AppVersion)
    : APP_VERSION.default;

  const initialVariants = (params.get(PARAMS.VARIANT)?.split(variantBreaker) ||
    getItem<AppVariant[]>(STORAGE.APP_VARIANT) ||
    []) as AppVariant[] | AppVariant;

  const variants: AppVariant[] = [];

  const addToVariants = (variant: AppVariant): void => {
    if (variant in APP_VARIANT) variants.push(variant);
  };
  const isVariantsArray = Array.isArray(initialVariants);

  const setVariants = (): void => {
    if (isVariantsArray) return initialVariants.forEach(addToVariants);
    addToVariants(initialVariants);
  };

  if (!isVariantsArray) SentryService.logMessage(JSON.stringify(initialVariants));
  setVariants();

  storeItem<string>(STORAGE.APP_VERSION, version);
  storeItem<AppVariant[]>(STORAGE.APP_VARIANT, variants);

  const isVersion = (versionCheckable: string): boolean => versionCheckable === version;
  const hasVariant = (variantCheckable: AppVariant): boolean => variants.includes(variantCheckable);

  return {
    version,
    variants,
    isVersion,
    hasVariant,
  };
};
