import styled from 'styled-components';
import { DialogActions, DialogTitle, DialogContent, Radio } from '@mui/material';
import { Button as BaseButton } from '../../atoms';
import { IconCheck } from '../../atoms/Icons';
import { ITheme } from '../../../interfaces/theme.interface';

export const Content = styled(DialogContent)`
  padding: 24px;
  padding-bottom: 0px;
  color: ${({ theme }: ITheme): string => theme.palette.text.secondary};
`;

export const Image = styled.div`
  width: 180px;
  margin-bottom: 20px;
`;

export const Title = styled(DialogTitle)`
  font-size: 20px;
  margin-bottom: 10px;
  padding: 0px;

  & * {
    font-weight: 700;
  }
`;

export const Button = styled(BaseButton)`
  font-weight: 300;
`;

export const BlackButton = styled(BaseButton)`
  font-weight: 300;
  color: ${({ theme: { palette } }: ITheme): string => palette.common.black};
`;

export const CheckedRadio = styled(Radio)`
  & .MuiIconButton-label {
    width: 24px;
    height: 24px;
  }
`;

export const RadioChecked = styled(IconCheck)`
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  width: 20px;
  height: 20px;
`;

export const Actions = styled(DialogActions)`
  padding: 0px 24px 4px;
  display: flex;
  gap: 8px;
  & > :not(:first-child) {
    margin: 0;
  }
  flex-direction: row;
  flex-basis: 50%;
`;
