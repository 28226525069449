import { useQueryClient } from 'react-query';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { ILoggedUser, IUser } from '../../interfaces/userData.interface';
import { QueryKeys } from '../../constants/queryKeys.const';
import { useSendCourseLink } from '../../hooks/useSendCourseLink';
import { Paragraph } from '../../components/Tokens/Typography';
import { formatPhone } from '../../utils/format';
import { Body, Button, Main } from '../../components/atoms';
import { NavBar, SnackMessage } from '../../components/organisms';
import { ROUTES } from '../../constants/routes.const';
import * as S from './style';
import { sendLink } from '../../constants/images.const';
import { CONTENT } from './mandatoryCourse.content';
import { isGivenCustomError } from '../../services/error.service';
import { ERROR_DETAILS } from '../../utils/serverErrorHandler';
import { openNewUrl } from '../../utils/route';
import { URLS } from '../../constants/urls.const';
import mandatoryCourseEvents from './mandatoryCourse.events';

const MandatoryCourse = (): JSX.Element => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<IUser>(QueryKeys.userData) as ILoggedUser;

  const { mutate, isSuccess, isLoading, isError, error } = useSendCourseLink();

  const { phone } = user.user_contact;
  useEffect(() => mandatoryCourseEvents.pageView(), []);

  const handleSendClick = () => {
    mandatoryCourseEvents.primaryClick();
    mutate();
  };
  const handleIfoodContactClick = () => {
    mandatoryCourseEvents.secondaryClick();
  };

  const snackMessageSuccess = {
    message: CONTENT.linkSuccessfullySended,
    open: true,
    onClose: (): void => undefined,
    onClick: (): void => undefined,
  };
  const snackMessageFail = {
    message:
      error && isGivenCustomError(error, ERROR_DETAILS.TOO_MANY_MSGS_TODAY)
        ? ERROR_DETAILS.TOO_MANY_MSGS_TODAY.MESSAGE
        : CONTENT.linkFailToSend,
    open: true,
    onClose: (): void => undefined,
    onClick: (): void => undefined,
  };
  return (
    <Body>
      <NavBar backRoute={ROUTES.CONTA_RECOMPENSA} title={CONTENT.header} />
      <Main>
        {isSuccess && (
          <Box marginTop={1} marginBottom={1}>
            <SnackMessage type="success" icon="check" position="static" {...snackMessageSuccess} />
          </Box>
        )}
        {isError && (
          <Box marginTop={1} marginBottom={1}>
            <SnackMessage type="error" icon="cancel" position="static" {...snackMessageFail} />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={2} flex={1}>
          <Box display="flex" gap={1} justifyContent="center" mt={3} flexDirection="column">
            <S.SplashImage src={sendLink} />
            <Paragraph color="textSecondary" align="center">
              {phone ? formatPhone(phone) : ''}
            </Paragraph>
          </Box>
          <Box display="flex" gap={1} justifyContent="center" mt={2} px={4} flexDirection="column">
            <Typography color="textSecondary" variant="h4" align="center">
              {CONTENT.title}
            </Typography>
            <Typography color="textSecondary" variant="h4" align="center">
              {CONTENT.subtitle}
            </Typography>
          </Box>

          <S.MainAction>
            <Button
              data-testid="primary-button"
              color="primary"
              type="button"
              onClick={handleSendClick}
              disabled={isLoading}
            >
              {CONTENT.primaryButton}
            </Button>
            <a {...openNewUrl(URLS.EXIT_WEBVIEW)}>
              <Button
                data-testid="secondary-button"
                color="primary"
                type="button"
                variant="text"
                onClick={handleIfoodContactClick}
              >
                {CONTENT.secondaryButton}
              </Button>
            </a>
          </S.MainAction>
        </Box>
      </Main>
    </Body>
  );
};

export default MandatoryCourse;
