import { Box } from '@mui/material';
import { ICreditCard } from '../../../interfaces/creditCard.interface';
import { maskCard } from '../../../utils/format';
import { brands, IconCard } from '../../atoms/Icons';
import * as S from './style';

interface ICreditCardFieldProps {
  creditCard: ICreditCard;
  onClick: () => void;
}

const CreditCardField: React.FC<ICreditCardFieldProps> = ({ creditCard, onClick }) => {
  const Brand = brands[creditCard.brand] ?? IconCard;

  return (
    <>
      <span>Método de pagamento</span>
      <S.CreditCardField
        id="creditCard"
        name="creditCard"
        variant="outlined"
        data-testid="creditCard"
        type="text"
        onClick={onClick}
        value={maskCard(creditCard.number)}
        InputProps={{
          startAdornment: (
            <Box marginRight={2}>
              <Brand />
            </Box>
          ),
          endAdornment: <S.Adornement position="end">Trocar</S.Adornement>,
        }}
      />
    </>
  );
};

export default CreditCardField;
