import { UseBuildCustomMutation } from '../interfaces/query.interface';
import { useGuardedMutation } from './useGuardedMutation';
import { buildPostRequest } from '../utils/request';
import { PENALTY } from '../API/tempartner';
import { EVENTS } from '../constants/events.const';

type Response = {
  message: string;
};

export const useCancelAutoMigration: UseBuildCustomMutation<Response> = (options = {}) => {
  return useGuardedMutation<Response>(() => {
    const url = PENALTY.PATCH;
    const success = {
      action: EVENTS.ACTIONS.PLAN_ENTREGADOR_CANCELAR_CARTAO_SUCCESS,
      category: EVENTS.CATEGORIES.PENALTY,
    };

    const error = {
      action: EVENTS.ACTIONS.PLAN_ENTREGADOR_CANCELAR_CARTAO_FAIL,
    };

    const events = { success, error };

    const request = buildPostRequest<Response>({ url }, events);
    return request();
  }, options);
};
