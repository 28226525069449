import { waitTime, opsSomethingWrong, cardError } from '../../constants/images.const';

export const CONTENT = {
  title: 'Tempo de disponibilidade com a bike para entregas iFood',
  block: [
    {
      id: 1,
      image: waitTime,
      message:
        'Para aproveitar todos os benefícios e condições do iFood Pedal, é necessário ficar mais de 50% do tempo disponível para entregas no app do iFood, enquanto você estiver com uma bike.',
    },
    {
      id: 2,
      image: opsSomethingWrong,
      message:
        'Nos próximos 7 dias, caso sua disponibilidade para entregas continue abaixo de 50%, o plano disponível para você sofrerá alterações no valor.',
    },
    {
      id: 3,
      image: cardError,
      message:
        'Entregadores que utilizam cartão de crédito, podem cancelar, a qualquer momento, a assinatura do novo plano. Os benefícios do iFood Pedal continuarão bloqueados.',
    },
  ],
  primaryButtonText: 'Ok, entendi',
  secondaryButtonText: 'Cancelar cartão de crédito',
  knowMore: 'Saiba mais',
};
