import { getEnv } from '../utils/env';

const CANCEL_PLAN_TYPEFORM = getEnv('REACT_APP_CANCEL_PLAN_TYPEFORM');

export const URLS = {
  CANCEL_PLAN_TYPEFORM: `https://tembici.typeform.com/to/${CANCEL_PLAN_TYPEFORM}#email={0}&region={1}&public_id={2}`,
  CANCEL_CURRENT_PLAN_TYPEFORM: 'https://tembici.typeform.com/to/jCPdqrLz',
  BIKE_ITAU_APP:
    'https://play.google.com/store/apps/details?id=pbsc.cyclefinder.tembici&hl=pt_BR&gl=US',
  TEMBICI_APP:
    'https://play.google.com/store/apps/details?id=pbsc.cyclefinder.tembicinosponsor&hl=pt_BR&gl=US',
  EXIT_WEBVIEW: 'fleet://home',
};
