import { useQueryClient } from 'react-query';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Body, Button, Main, Spinner } from '../../components/atoms';
import { NavBar } from '../../components/organisms';
import { ROUTES } from '../../constants/routes.const';
import * as S from './style';
import { waiting } from '../../constants/images.const';
import { CONTENT } from './warningList.content';
import { IconBlock, IconDebitCard, IconEBike } from '../../components/atoms/Icons';
import { ILoggedUser, IUser } from '../../interfaces/userData.interface';
import { QueryKeys } from '../../constants/queryKeys.const';
import { REGIONS } from '../../constants/regions.const';
import { useGetFuturePlan } from '../../hooks/useGetFuturePlan';
import { normalizePlan } from '../../utils/plan';
import warningListEvents from './warningList.events';

const WarningList = (): JSX.Element => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<IUser>(QueryKeys.userData) as ILoggedUser;
  const navigate = useNavigate();
  const { data, isLoading } = useGetFuturePlan();

  useEffect(() => warningListEvents.pageView(), []);

  const isNotBikeRio = user.location.region !== REGIONS.RJ;

  const newPlan = data ? normalizePlan(data.new_plan) : null;

  const unlockTypeMessage =
    user?.location?.external_app === 'Tembici' ? CONTENT.externalTembici : CONTENT.externalBikeItau;

  const newPlanName = newPlan ? `: ${newPlan.name} ${newPlan.value.formated_price}` : '.';

  if (isLoading) return <Spinner />;

  const handleClick = () => {
    navigate(ROUTES.WARNING_LIST_INFO);
  };

  return (
    <Body>
      <NavBar backRoute={ROUTES.HUB} title={CONTENT.header} />
      <Main>
        <Box display="flex" flexDirection="column" gap={2} flex={1}>
          <Box display="flex" gap={1} justifyContent="center" mt={3} flexDirection="column" px={2}>
            <S.SplashImage src={waiting} />
            <Typography align="center" color="textSecondary" variant="h3" fontWeight="bold">
              {CONTENT.title}
            </Typography>
          </Box>
          <Box display="flex" gap={1} justifyContent="center" mt={2} px={4} flexDirection="column">
            <Typography color="textSecondary" variant="h4" align="center">
              {CONTENT.subtitle}
            </Typography>
          </Box>

          <Box display="flex" gap={1} alignItems="center" px={1}>
            <IconBlock />
            <Typography color="textSecondary" variant="h4" align="left">
              {CONTENT.accessRestriction}
            </Typography>
          </Box>

          {isNotBikeRio && (
            <Box display="flex" gap={1} alignItems="center" px={1}>
              <IconEBike />
              <Typography color="textSecondary" variant="h4" align="left">
                {unlockTypeMessage}
              </Typography>
            </Box>
          )}

          <Box display="flex" gap={1} alignItems="center" px={1}>
            <IconDebitCard />
            <Typography color="textSecondary" variant="h4" align="left">
              {`${CONTENT.planChange}${newPlanName}`}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Button data-testid="primary-button" color="primary" type="button" onClick={handleClick}>
            {CONTENT.knowMore}
          </Button>
        </Box>
      </Main>
    </Body>
  );
};

export default WarningList;
