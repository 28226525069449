import { useState } from 'react';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import InputMask, { Props as IMaskProps } from 'react-input-mask';

import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Optional } from '../../../utils/interface';
import * as S from './style';

interface IBaseFieldProps {
  inputMode?: string;
  variant?: string;
  fixedWidth?: number;
  InputProps?: Record<string, unknown>;
  checked?: boolean;
  persistedError?: string;
}
type IConditionalMask = Optional<IMaskProps, 'mask'>;
export type IReactHookProps<TFieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
};

type HookField<TFieldValues> = IBaseFieldProps &
  IConditionalMask &
  TextFieldProps &
  IReactHookProps<TFieldValues>;

type UsePassword = (originalType: string) => {
  type: string;
  toggleVisibility: () => void;
  pswdVisible: boolean;
  isPasswordField: boolean;
  PasswordInput: () => JSX.Element;
};
const usePasswordField: UsePassword = originalType => {
  const [pswdVisible, setPswdVisible] = useState(false);
  const toggleVisibility = (): void => setPswdVisible(v => !v);

  const isPasswordField = originalType === 'password';
  const passwordFieldType = pswdVisible ? 'text' : 'password';

  const type = isPasswordField ? passwordFieldType : originalType;

  const PasswordInput = (): JSX.Element => (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={toggleVisibility}>
        {pswdVisible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return { type, toggleVisibility, pswdVisible, isPasswordField, PasswordInput };
};

export type FieldComponent = <TFieldValues extends FieldValues>(
  props: HookField<TFieldValues>,
) => JSX.Element;
const Field: FieldComponent = props => {
  const {
    type: originalType = 'text',
    control,
    name,
    persistedError,
    mask,
    maskChar,
    checked,
    inputMode,
    variant = 'standard',
    formatChars,
    fixedWidth,
    onFocus,
    onChange,
    onBlur,
    beforeMaskedValueChange,
    InputProps = {},
    ...rest
  } = props;

  const { field, fieldState } = useController({ control, name });
  const { error, isTouched } = fieldState;
  const { value, ref } = field;

  const { type, isPasswordField, PasswordInput } = usePasswordField(originalType);

  if (isPasswordField) InputProps.endAdornment = <PasswordInput />;

  const hasError = !!persistedError || (isTouched && !!error);

  const helperText = persistedError || (isTouched ? error?.message : '');

  const baseFieldProps = {
    inputMode,
    variant,
    InputProps,
    type,
    name,
    inputRef: ref,
    fullWidth: true,
    $fixedWidth: fixedWidth,
    error: hasError,
    helperText,
    autoComplete: 'off',
    ...rest,
  };

  const handlers = {
    onBlur: onBlur ?? field.onBlur,
    onChange: onChange ?? field.onChange,
    onFocus,
  };

  return (
    <>
      {mask ? (
        <InputMask
          value={value}
          beforeMaskedValueChange={beforeMaskedValueChange}
          disabled={rest.disabled}
          mask={mask}
          maskChar={null}
          formatChars={formatChars}
          {...handlers}
        >
          {(maskProps: Record<string, unknown>): unknown => (
            <S.Field {...maskProps} {...baseFieldProps} />
          )}
        </InputMask>
      ) : (
        <S.Field value={value} {...handlers} {...baseFieldProps} />
      )}
    </>
  );
};

export default Field;
