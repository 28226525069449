import { ListItem } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import * as S from './style';

interface ICheckItemProps {
  benefit: string;
}

const CheckItem: React.FC<ICheckItemProps> = ({ benefit, ...rest }) => (
  <ListItem disableGutters {...rest}>
    <S.Item>
      <DoneIcon fontSize="inherit" />
      <S.ItemText primaryTypographyProps={{ variant: 'body2' }} primary={benefit} />
    </S.Item>
  </ListItem>
);

export default CheckItem;
