import { useContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import { Spinner } from './components/atoms';
import { useUser } from './hooks/useUser';
import Routes from './routes';
import { UserStatus } from './interfaces/userData.interface';
import Sentry from './services/sentry.service';
import { DriverContext } from './contexts/DriverContext';
import { AnalyticsService } from './modules/temAnalytics';
import { useGlobalLoadingStore } from './stores/useGlobalLoadingStore';
import { FirebaseService } from './services/firebase';
import { BrazeService } from './services/braze';
import { MonitorService } from './modules/temMonitor';

const Bootstrap: React.FC = () => {
  const { publicId } = useContext(DriverContext);
  const { isLoading, user } = useUser();
  const { isGlobalLoading } = useGlobalLoadingStore();

  const initializeServices = async () => {
    FirebaseService.instance.initializeApp();
    await BrazeService.instance.initializeApp();
    MonitorService.setup({
      firebaseApp: FirebaseService.instance.app,
    });
    AnalyticsService.setup({
      firebaseApp: FirebaseService.instance.app,
      brazeApp: BrazeService.instance.app,
    });
  };

  useEffect(() => {
    initializeServices();
  }, []);

  useEffect(() => {
    if (!user) return;

    AnalyticsService.instance.setUser({
      user: {
        email: user.user_contact.tembici_email,
        id: user.user_contact.tembici_email,
      },
    });

    Sentry.setUser(publicId);
    if (user.user_status === UserStatus.loggedIn) {
      const { id } = jwtDecode<{ id: number }>(user.refresh_token);
      AnalyticsService.instance.setUser({
        user: {
          email: user.user_contact.tembici_email,
          id: String(id),
        },
      });
    }
  }, [publicId, user]);

  return !isLoading && !isGlobalLoading ? <Routes /> : <Spinner />;
};

export default Bootstrap;
