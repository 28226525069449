import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../constants/queryKeys.const';
import { IPlan } from '../interfaces/plan.interface';

export const enum Payments {
  creditCard = 'credit-card',
  externalPayment = 'external-payment',
  none = '',
}

export type PayementMethod = 'credit-card' | 'external-payment' | '';

export interface IPlanPurchaseContext {
  paymentMethod: PayementMethod;
  selectedPlanId: number | null;
  setSelectedPlanId: Dispatch<SetStateAction<number | null>>;
  setPaymentMethod: Dispatch<SetStateAction<PayementMethod>>;
  selectedPlan: IPlan | undefined;
  isCreditCard: () => boolean;
  isExternalPayment: () => boolean;
}

export const PlanPurchaseInitialValues: IPlanPurchaseContext = {
  paymentMethod: '',
  selectedPlanId: null,
  selectedPlan: undefined,
  setSelectedPlanId: () => undefined,
  setPaymentMethod: () => undefined,
  isCreditCard: () => false,
  isExternalPayment: () => false,
};

export const PlanPurchaseContext = createContext(PlanPurchaseInitialValues);

export const usePlanPurchase = (): IPlanPurchaseContext => {
  const [paymentMethod, setPaymentMethod] = useState<PayementMethod>('');
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);

  const queryClient = useQueryClient();
  const plans = queryClient.getQueryData<IPlan[]>([QueryKeys.plans, paymentMethod]);

  const selectedPlan = plans?.find(plan => selectedPlanId === plan.id);

  const isExternalPayment = (): boolean => paymentMethod === Payments.externalPayment;
  const isCreditCard = (): boolean => paymentMethod === Payments.creditCard;

  return {
    isExternalPayment,
    isCreditCard,
    paymentMethod,
    selectedPlanId,
    setSelectedPlanId,
    setPaymentMethod,
    selectedPlan,
  };
};
