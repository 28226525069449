import { BoxProps } from '@mui/material';
import * as S from './style';

export interface ISplashImageProps extends BoxProps {
  alt: string;
  src: string;
}

const SplashImage: React.FC<ISplashImageProps> = ({ alt, src, ...rest }): JSX.Element => (
  <S.Container {...rest}>
    <img data-testid="splash-image" alt={alt} src={src} width="100%" />
  </S.Container>
);
export default SplashImage;
