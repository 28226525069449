import {
  ICustomError,
  IStandardError,
  ICustomErrorData,
  ICustomFormData,
  IError,
  IErrorNormalized,
} from '../interfaces/error.interface';
import { serverErrorHandler, ERROR_DETAILS } from '../utils/serverErrorHandler';

export const errorService = (err: IError): IErrorNormalized => {
  if (typeof err === 'string') return ERROR_DETAILS.SERVER_ERROR;
  if (!err || 'message' in err) return ERROR_DETAILS.SERVER_ERROR;
  if ('detail' in err) return serverErrorHandler(err as ICustomError);
  const { status_code, ...rest } = err;
  return { STATUS: status_code, ERRORS: rest };
};

export const isStandardError = (err: IErrorNormalized | null): err is IStandardError =>
  !!err && 'message' in err;

export const isFormError = (err: IErrorNormalized | null): err is ICustomFormData =>
  !!err && 'ERRORS' in err;

export const getFormError = (err: IErrorNormalized | null): { [key: string]: string } | null => {
  return isFormError(err) ? err.ERRORS : null;
};

export const isCustomError = (err: IErrorNormalized | null): err is ICustomErrorData =>
  !!err && 'TYPE' in err;

type FilterError = {
  includes?: ICustomErrorData[];
  excludes?: ICustomErrorData[];
};

export const getCustomError = (
  err: IErrorNormalized | null,
  filter: FilterError = { includes: [], excludes: [] },
): string | null => {
  if (!isCustomError(err)) return null;
  const { includes, excludes } = filter;

  if (includes?.length) {
    return includes.some(({ TYPE }) => TYPE === err.TYPE) ? err.MESSAGE : null;
  }

  if (excludes?.length) {
    return excludes.every(({ TYPE }) => TYPE !== err.TYPE) ? err.MESSAGE : null;
  }

  return err.MESSAGE;
};

export const isGivenCustomError = (
  err: IErrorNormalized | null,
  customError: ICustomErrorData | ICustomErrorData[],
): boolean => {
  if (!isCustomError(err)) return false;
  if (Array.isArray(customError)) {
    return customError.some(({ TYPE }) => TYPE === err.TYPE);
  }
  return err.TYPE === customError.TYPE;
};

const catastrophicErrors = [ERROR_DETAILS.CIRCUIT_BREAKER_OPENED];
const penaltyErrors = [ERROR_DETAILS.USER_HAS_A_PENALTY_AND_IT_S_NOT_ALLOWED];
const blockErrors = [ERROR_DETAILS.USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED];

export const isCatastrophicError = (err: IErrorNormalized | null): boolean =>
  isGivenCustomError(err, catastrophicErrors);

export const isPenaltyError = (err: IErrorNormalized | null): boolean =>
  isGivenCustomError(err, penaltyErrors);

export const isBlockError = (err: IErrorNormalized | null): boolean =>
  isGivenCustomError(err, blockErrors);
