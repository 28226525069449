import { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';
import { ITheme } from '../interfaces/theme.interface';

type Dim = string | number;

interface IDimension {
  dim: Dim;
}

interface ISquare extends IDimension, ITheme {}

/**
 * getDimension
 * @param dim dimension
 * @returns extract a spacing theme value for a number dim. for a string returns itself
 */
const getDimension = ({ dim, theme }: ISquare): string =>
  typeof dim === 'string' ? dim : `${theme.spacing(dim)}`;

type SquareFn = (props: ITheme) => CssStyles;

/**
 * @param dim dimension
 * @returns element with same width and height for a given dimension
 */
export const square =
  (dim: Dim): SquareFn =>
  ({ theme }: ITheme): CssStyles =>
    css`
      width: ${getDimension({ dim, theme })};
      height: ${getDimension({ dim, theme })};
    `;

/**
 * @param dim diameter
 * @returns a circle element for a given diameter
 */
export const circle =
  (dim: Dim): SquareFn =>
  ({ theme }: ITheme): CssStyles =>
    css`
      width: ${getDimension({ dim, theme })};
      height: ${getDimension({ dim, theme })};
      border-radius: 50%;
    `;

/**
 * @returns a centered flex element
 */
export const centerFlex = (): CssStyles =>
  css`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

/**
 * @returns a space-between flex element
 */
export const spaceBetween = (): CssStyles =>
  css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

export const spaceAround = (): CssStyles =>
  css`
    display: flex;
    align-items: center;
    justify-content: space-around;
  `;
