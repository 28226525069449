import React, { useState } from 'react';
import { DefaultValues, FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import Zip from '../../../services/zip.service';
import Field from '../Field';

interface IBaseFormProps {
  street_number: string;
  details?: string;
  zip_code: string;
}

type OnChangeAsync = (event: React.SyntheticEvent) => Promise<void>;
const CEP_LENGTH = 8;

interface ZipFieldProps<TFieldValues extends FieldValues> {
  onFocus: () => void;
  errorMessage: string;
  name: Path<TFieldValues>;
  defaultValues: DefaultValues<TFieldValues>;
  form: UseFormReturn<TFieldValues>;
}

const ZipField = <IFormProps extends IBaseFormProps>({
  form,
  onFocus,
  name,
  defaultValues,
  errorMessage,
}: ZipFieldProps<IFormProps>): JSX.Element => {
  const { control, getValues, setValue, reset } = form;
  const [invalidZip, setInvalidZip] = useState(false);
  const persistedError = invalidZip ? errorMessage : '';

  const clearMask = (event: React.SyntheticEvent): string =>
    (event.target as HTMLInputElement).value.replace(/\D/g, '');

  const onZipChange: OnChangeAsync = async event => {
    setInvalidZip(false);
    const zipCode = clearMask(event);
    const { street_number, details } = getValues();
    const resetForm = { ...defaultValues, zip_code: zipCode, street_number, details };

    if (zipCode.length !== CEP_LENGTH) {
      reset(resetForm);
      return;
    }

    setValue(name, zipCode as PathValue<IFormProps, Path<IFormProps>>);
    try {
      const { service, cep: zip, ...address } = await Zip.get(zipCode);

      reset({ ...defaultValues, ...address, zip_code: zipCode });
    } catch {
      setInvalidZip(true);
    }
  };

  return (
    <Field
      id={name}
      name={name}
      data-testid={name}
      label="CEP"
      control={control}
      mask="99999-999"
      onChange={onZipChange}
      persistedError={persistedError}
      inputMode="numeric"
      onFocus={onFocus}
    />
  );
};

export default ZipField;
