import { ReactElement } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate } from 'react-router-dom';
import { QueryKeys } from '../../constants/queryKeys.const';
import { ROUTES } from '../../constants/routes.const';
import { IUser } from '../../interfaces/userData.interface';
import { pendingLogin } from '../../utils/user';

interface IGuard {
  [key: string]: unknown;
  children: ReactElement;
}

export const LoggedRoute = ({ children }: IGuard): JSX.Element => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<IUser>(QueryKeys.userData) as IUser;

  if (pendingLogin(user.user_status)) return <Navigate to={ROUTES.MAIORES_DETALHES} />;

  return children;
};

export default LoggedRoute;
