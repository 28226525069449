import { BoxProps, Box, Typography } from '@mui/material';
import { BulletList } from '../../molecules';

interface IInstructionProps extends BoxProps {
  content: string;
  items: string[];
  itemsSpaced?: boolean;
}

const Instruction = ({
  content,
  items,
  itemsSpaced,
  ...boxProps
}: IInstructionProps): JSX.Element => {
  return (
    <Box component="section" marginY={3} data-testid="instruction" {...boxProps}>
      <Typography fontWeight={700} variant="p5" paragraph>
        {content}
      </Typography>
      <BulletList itemsSpaced={itemsSpaced} items={items} />
    </Box>
  );
};

export default Instruction;
