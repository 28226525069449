export type EventType =
  | 'interaction'
  | 'noninteraction'
  | 'screen_view'
  | 'page_view'
  | 'modal_view'
  | 'card_view';

export enum AnalyticsDestination {
  BRAZE,
  FIREBASE,
}
export interface EventParams {
  [key: string]: unknown;
}

export interface EmitEventParams<T extends string> {
  name: T;
  type?: EventType;
  destinations?: AnalyticsDestination[];
  params?: EventParams;
}
export type EmitEventType<T extends string> = (params: EmitEventParams<T>) => void;

export type GetLatLong = () => [number?, number?];

export interface AnalyticsServiceInterface<T extends string> {
  getLatLong: GetLatLong;
  emitEvent: EmitEventType<T>;
  setUser: (setUserParams: SetUserParams) => void;
  setUserProperty: (setUserPropertyParams: SetUserPropertyParams) => void;
}

export const EVENT_ALL_DESTINATIONS = [AnalyticsDestination.BRAZE, AnalyticsDestination.FIREBASE];

export type User = {
  id?: string;
  email?: string;
};

export type SetUserParams = {
  user: User;
  destinations?: AnalyticsDestination[];
};

export type SetUserPropertyParams = {
  key: string;
  value: string;
  destinations?: AnalyticsDestination[];
};
