import { Box, Slide, SlideProps } from '@mui/material';
import QRCode from 'qrcode.react';
import { forwardRef, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { QueryKeys } from '../../../constants/queryKeys.const';
import { ROUTES } from '../../../constants/routes.const';
import { useQrCode } from '../../../hooks/useQrCode';
import { useTimer } from '../../../hooks/useTimer';
import { IErrorNormalized } from '../../../interfaces/error.interface';
import { IQRCode } from '../../../interfaces/qrCode.interface';
import { MessageTypes } from '../../../pages/InfoView/messages.const';
import { isGivenCustomError } from '../../../services/error.service';
import { formatTime, setMaskedDocument } from '../../../utils/format';
import { messageRoute } from '../../../utils/route';
import { ERROR_DETAILS } from '../../../utils/serverErrorHandler';
import { Button, Spinner, Tooltip } from '../../atoms';
import { IconStopWatch } from '../../atoms/Icons';
import Alert from '../Alert';
import { CONTENT } from './qrCodeDialog.content';
import { alertDataContent } from './qrCodeDialog.error';
import QrCodeDialogEvents from './qrCodeDialog.events';
import * as S from './style';

const Transition = forwardRef((props: SlideProps, ref: React.Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface IQrCodeDialogProps {
  open: boolean;
  handleClose: () => void;
  qrCodeExpirationTime?: number;
}
const QrCodeDialog: React.FC<IQrCodeDialogProps> = ({
  open,
  handleClose,
  qrCodeExpirationTime = 10,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { time, restartClock } = useTimer(qrCodeExpirationTime);

  const onError = (err: IErrorNormalized): void => {
    let route = null;
    if (isGivenCustomError(err, ERROR_DETAILS.USER_PLAN_SUSPENDED)) {
      route = messageRoute(MessageTypes.SUSPENDED_ERROR_QRCODE);
    }

    if (isGivenCustomError(err, ERROR_DETAILS.USER_HAS_NO_CURRENT_PLAN)) {
      route = messageRoute(MessageTypes.NO_PLAN_ERROR_QRCODE);
    }

    if (route) navigate(route);
  };

  const { data, error, isFetching } = useQrCode({
    onError,
    onSuccess: restartClock,
    refetchOnMount: 'always',
    enabled: open,
  });

  const generateNewQrcode = (): void => {
    queryClient.refetchQueries([QueryKeys.qrCode]);
  };
  const onGoBack = (): void => navigate(ROUTES.HUB);
  const onConfirm = (): void => navigate(messageRoute(MessageTypes.SUCCESS_QRCODE));
  const handleAlertOnClose = (): void => navigate(ROUTES.HUB);

  const alertData = alertDataContent(error);

  useEffect(() => {
    if (isFetching) QrCodeDialogEvents.onClick();
  }, [isFetching]);

  if (isFetching) return <Spinner />;

  if (alertData) return <Alert {...alertData} open handleClose={handleAlertOnClose} />;

  const { qrcode, name, document } = data as IQRCode;
  const formattedqrCodeExpirationTime = formatTime(time);
  const formattedDocument = setMaskedDocument(document);

  return (
    <S.CustomDialog
      data-testid="qr-code-dialog"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullScreen
      onClose={handleClose}
      aria-labelledby="qrcode-dialog"
      aria-describedby="desbloqueio-de-bike-por-qrcode"
    >
      <S.Content>
        <S.Line data-testid="close-qrcode" onClick={handleClose} />

        <S.Title>{CONTENT.title}</S.Title>
        <Tooltip title={CONTENT.tooltip} arrow>
          <S.Highlighted>{CONTENT.question}</S.Highlighted>
        </Tooltip>

        <S.QrCodeContainer>
          <QRCode value={qrcode} size={120} />
          <S.ClockContainer>
            <IconStopWatch />
            <Box marginTop={0.5}>{formattedqrCodeExpirationTime}</Box>
          </S.ClockContainer>
          <Button
            data-testid="generate-new-qrcode"
            variant="text"
            onClick={generateNewQrcode}
            disabled={!!time}
          >
            {CONTENT.newCode}
          </Button>
        </S.QrCodeContainer>

        <section>
          <p>{formattedDocument}</p>
          <p>{name}</p>
        </section>

        <S.ActionsContainer>
          <Button color="primary" data-testid="confirm" onClick={onConfirm}>
            {CONTENT.onStart}
          </Button>
          <Button variant="text" data-testid="return" color="primary" onClick={onGoBack}>
            {CONTENT.onReturn}
          </Button>
        </S.ActionsContainer>
      </S.Content>
    </S.CustomDialog>
  );
};

export default QrCodeDialog;
