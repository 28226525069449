import { FirebaseApp } from 'firebase/app';
import { MonitorDestination, MonitorServiceInterface, StartTraceParams } from '../../temCore';
import { FirebaseMonitorService } from '../core';
import { MonitorSetup, Monitor } from '../core/types';

export class MonitorService implements MonitorServiceInterface {
  /* ------------------------------- Properties ------------------------------- */
  #firebaseApp?: FirebaseApp;

  static #instance: MonitorService;

  static get instance(): MonitorService {
    if (!MonitorService.#instance) {
      MonitorService.#instance = new MonitorService();
    }
    return MonitorService.#instance;
  }

  static readonly setup = ({ firebaseApp }: MonitorSetup): void => {
    MonitorService.instance.#firebaseApp = firebaseApp;
  };

  /* ------------------------------- Methods ------------------------------- */
  startTrace({ name, destination }: StartTraceParams): Monitor | undefined {
    switch (destination) {
      case MonitorDestination.FIREBASE:
        if (this.#firebaseApp) {
          return new FirebaseMonitorService(name, this.#firebaseApp).start();
        }
        break;
      default:
        break;
    }

    return undefined;
  }
}
