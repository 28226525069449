import { useContext } from 'react';
import { PENALTY } from '../API/tempartner';
import { QueryKeys } from '../constants/queryKeys.const';
import { DriverContext } from '../contexts/DriverContext';
import { UseBuildCustomQuery } from '../interfaces/query.interface';
import { buildGetRequest } from '../utils/request';
import { useGuardedQuery } from './useGuardedQuery';
import { IPLanResponse } from '../interfaces/plan.interface';

interface IFuturePlanResponse {
  new_plan: IPLanResponse;
}
export const useGetFuturePlan: UseBuildCustomQuery<IFuturePlanResponse> = (options = {}) => {
  const { region } = useContext(DriverContext);
  const params = [region];
  const url = PENALTY.FUTURE_PLAN.GET;

  const request = buildGetRequest<IFuturePlanResponse>({
    url,
    params,
  });

  return useGuardedQuery<IFuturePlanResponse>([QueryKeys.futurePlan], request, options);
};
