import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import { spacing } from '../../../themes/_helpers';

export const CustomSwiper = styled(Swiper)`
  &.swiper-container {
    width: 100%;
    max-width: ${spacing(44)};
    padding-bottom: ${spacing(3)};
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }

    .swiper-pagination-bullets {
      bottom: 0;

      .swiper-pagination-handle {
        padding: 2px;
      }

      .swiper-pagination-bullet {
        padding: 0px;
        border: none;
      }
    }
  }
`;
