import { Children } from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay, Virtual, SwiperOptions } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import * as S from './style';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

const Carousel: React.FC<Swiper> = ({ children, ...settings }) => {
  const sliderConfig: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: true,
  };

  return (
    <S.CustomSwiper {...sliderConfig} {...settings}>
      {Children.map(children, child => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </S.CustomSwiper>
  );
};

export default Carousel;
