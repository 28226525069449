import styled, { css, FlattenSimpleInterpolation as CssStyles } from 'styled-components';
import { TextField } from '@mui/material';
import { ITheme } from '../../../interfaces/theme.interface';

const colorGrey = ({ theme }: ITheme): string => theme.palette.grey[600];

interface IFieldProps extends ITheme {
  $fixedWidth?: number;
}

export const Field = styled(TextField)<IFieldProps>`
  margin-bottom: 16px;
  font-size: 16px;
  height: 70px;

  ${({ $fixedWidth, theme }: IFieldProps): CssStyles | false =>
    !!$fixedWidth &&
    css`
      flex-shrink: 0;
      width: ${theme.spacing($fixedWidth)};
    `}

  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid ${colorGrey};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colorGrey};
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }

  .MuiOutlinedInput-input {
    line-height: 20px;
  }
`;
