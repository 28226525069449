import { SIGNUP, ACCESS, VALIDATIONS } from '../API/tempartner';
import { STORAGE } from '../constants/storage.const';
import { IAccess } from '../interfaces/access.interface';
import { getItem } from './localStorage';

export const buildBearer = (bearerToken: string): string => `Bearer ${bearerToken}`;

export const returnToken = (userAccess: IAccess): string =>
  userAccess.access_token || userAccess.refresh_token;

export const buildAuth = (): string | void => {
  const userAccess = getItem<IAccess>(STORAGE.USER_DATA);
  if (!userAccess) return;

  return buildBearer(returnToken(userAccess));
};

const noAuthUrls: string[] = [
  VALIDATIONS.USER,
  SIGNUP.NEW,
  ACCESS.LOGIN.EMAIL,
  ACCESS.LOGIN.DOCUMENT,
  ACCESS.RECOVERY_PASSWORD,
];

export const authRequired = (url: string): boolean =>
  !noAuthUrls.some(noAuthUrl => url.includes(noAuthUrl));
