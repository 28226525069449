import { EVENTS } from '../../constants/events.const';
import Events from '../../services/events.service';

export default {
  pageView: (): void => {
    Events.logEvent({
      action: EVENTS.ACTIONS.RESEND_WHATSAPP_VIEW,
      category: EVENTS.CATEGORIES.PAGE,
      label: EVENTS.LABELS.SUCCESS,
    });
  },
  primaryClick: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.RESEND_WHATSAPP_CLICK,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    }),
  secondaryClick: (): void =>
    Events.logEvent({
      action: EVENTS.ACTIONS.CALL_CHANGE_NUMBER,
      category: EVENTS.CATEGORIES.CLICK,
      label: EVENTS.LABELS.SUCCESS,
    }),
};
